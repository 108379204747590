import type { Student } from "./student"

export class Room {
  id: string = ""
  index: number = 999
  shortName: string = 'Nouvelle chambre'
  
  isForReducedMobility: boolean = false
  isWatcherRoom: boolean = false
  isPairMaster: boolean = false
  
  roomComment: string = ""
  isArchived: boolean = false
  floorRef: string
  nbStudents: number = 0
  students: Array<Student> = []

  roomGender: string = ''

  // Plan position
  xPosition: number = -1
  yPosition: number = -1
  width: number = 1 // by default, all rooms have a width of 1

  constructor(floorRef: string) {
    this.floorRef = floorRef
  }
}
