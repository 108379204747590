import { Room } from './room'

export class Floor {
  id: string = ''
  index: number = 999
  shortName: string = 'Nouvel étage'
  isArchived: boolean = false
  rooms: Array<Room> = []
  buildingRef: string

  constructor(buildingRef: string) {
    this.buildingRef = buildingRef
  }
}
