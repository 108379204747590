export const genders = ["male", "female", ""]
export const gendersItems = genders.map(gender => {
  let label = ""
  switch(gender) {
    case "male":
      label = "Garçon"
      break;
    case "female":
      label = "Fille"
      break;
    case "":
      label = ""
      break;
  }
  return {
    value: gender,
    label
  }
})

