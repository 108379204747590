<script setup lang="ts">
import { useProfileStore } from '@/stores/profile';
import { Room } from '@/stores/types/room';
import type { Student } from '@/stores/types/student';
import type { Ref } from 'vue';
import type { PropType } from 'vue';
import { onUpdated, onMounted } from 'vue';
import { ref } from 'vue';
import StudentItem from '@/components/StudentItem.vue';
import { useStudentsStore } from '@/stores/students';

const profileStore = useProfileStore()

const props = defineProps({
  buildingId: {type: String, required: true},
  floorName: {type: String as PropType<string>, required: true},
  rooms: {type: Array as PropType<Array<Room>>, required: true},
})
const emit = defineEmits(['roomUpdated', 'print'])

const selectAdd1 = ref()
const selectAdd2 = ref()
const selectItems: Ref<Array<Room>> = ref([])
const topRooms: Ref<Array<Room>> = ref([])
const bottomRooms: Ref<Array<Room>> = ref([])
// Not modifiable by default
const modifiable = ref(false)
const corridorSize = ref(0)

const studentsStore = useStudentsStore()
const selectedStudent: Ref<Student | undefined> = ref()

const showPrintModale = ref(false)
const includeStudentNames = ref(true)

onMounted(() => {
  refreshRooms()
})
onUpdated(() => {
  refreshRooms()
})

function refreshRooms() {
  selectItems.value = props.rooms.filter(x => x).sort((a, b) => a.shortName.localeCompare(b.shortName))
  topRooms.value = props.rooms.filter(x => x.width > 0 && x.yPosition === 0).sort((a, b) => a.xPosition - b.xPosition)
  bottomRooms.value = props.rooms.filter(x => x.width > 0 && x.yPosition === 1).sort((a, b) => a.xPosition - b.xPosition)
  updateCorridorSize()
}

function updateCorridorSize() {
  const topRoomsCount = topRooms.value.length
  const bottomRoomsCount = bottomRooms.value.length
  corridorSize.value = Math.max(topRoomsCount, bottomRoomsCount) + (modifiable.value ? 1 : 0)
}

function roomChanged(roomId: string, xPosition: number, yPosition: number) {
  const room = selectItems.value.find(x => x.id === roomId)
  // Old Room is not at this place anymore (if any)
  const previousRooms = props.rooms.filter(x => x.xPosition === xPosition && x.yPosition === yPosition)
  previousRooms.forEach((prevRoom) => {
    prevRoom.index = 999
    prevRoom.yPosition = -1
    prevRoom.xPosition = -1
    prevRoom.width = 0
    emit('roomUpdated', prevRoom)
  })

  // Selected Room is now at this place
  if (room) {
    room.yPosition = yPosition
    room.xPosition = xPosition
    room.index = xPosition * 10 + yPosition
    room.width = 1

    // notify updated rooms
    emit('roomUpdated', room)
  } else if (!roomId) {
    // Cleared a room => refresh all rooms xPosition according to their index in the list
    let rooms = topRooms.value
    if (yPosition === 1) {
      rooms = bottomRooms.value
    }
    rooms.forEach((room, roomIndex) => {
      room.xPosition = roomIndex
      emit('roomUpdated', room)
    })
  }

  // reset selected choice in "Add" select
  selectAdd1.value = undefined
  selectAdd2.value = undefined

  refreshRooms()
}

function reset() {
  props.rooms.forEach((room) => {
    room.index = 999
    room.yPosition = -1
    room.xPosition = -1
    room.width = 0
    emit('roomUpdated', room)
  })

  refreshRooms()
}

function bgColor(room: Room): string {
  if (room.isWatcherRoom) {
    return 'bg-red'
  } else if (room.isForReducedMobility) {
    return 'bg-success'
  } else if (room.isPairMaster) {
    return 'bg-yellow'
  } else if (room.nbStudents <= 0) {
    return 'stripes'
  } else {
    return 'bg-primary'
  }
}

function listStudentClasses(room: Room, student: Student | undefined): Array<string> {
  return ['student', bgColor(room), student ? '' : 'free', student?.isRoomReferent || student?.isPairMaster ? 'emphasize': '']
}

</script>

<style>
.full-width {
  padding: 0;
  max-width: max-content;
}
.full-width > .v-card > .v-card-item {
  padding: 0;
  margin-left: -16px;
}
.stripes {
  background: repeating-linear-gradient(
    45deg,
    #bac1ec,
    #a3a6b6 10px,
    #bac1ec 10px,
    #a3a6b6 20px
  );
}
.plan {
  overflow-x: auto;
  padding-top: 0;
  padding-bottom: 0;
}
.roomHeight {
  flex-wrap: nowrap;
}
.roomHeight > .v-sheet {
  min-width: 200px;
  width: 200px;
  max-width: 200px;
  overflow-x: clip;
}
.plan .roomHeight.corridor > .v-sheet {
  min-width: 208px;
  width: 208px;
  max-width: 208px;
  font-weight: bold;
  font-size: 18px;
  z-index: -1;
}
.plan .roomHeight.corridor > .v-sheet > div {
  line-height: 50px;
  vertical-align: middle;
}
.plan .v-list {
  background-color: transparent
}
.plan .v-list .v-list-item.free .v-list-item-title {
  font-weight: bold;
  font-style: italic;
  text-align: center;
}
.v-list-item__apend {
  max-width: 52px;
}
.emphasize * {
  font-weight: bold;
}
.emphasize .mdi-fire, .emphasize .mdi-certificate {
  color: rgb(var(--v-theme-error));
  opacity: 1;
  background-color: white;
  border-radius: 50%;
}
.emphasize .mdi-certificate {
  color: rgb(var(--v-theme-success));
  border-radius: 25%;
}
.plan .v-list-item-title {
  white-space: normal;
}
.plan .v-input__details {
  display: none;
}
</style>

<template>
  <v-container class="full-width">
    <v-card>
      <v-dialog>
        <template v-slot:activator="{ props: print }">
          <v-card-title>
            <v-row no-gutters>
              <h3>Plan {{ floorName }}</h3>
              <v-spacer></v-spacer>
              <v-btn @click="showPrintModale = true" v-bind="print" icon="mdi-printer"></v-btn>
              <v-btn v-if="profileStore.current?.role === 'admin'" class="mx-1" variant="outlined" @click="() => {
                modifiable = !modifiable
                updateCorridorSize()
              }" :icon="modifiable ? 'mdi-eye' : 'mdi-pencil'"></v-btn>
              <v-btn v-if="modifiable" class="mx-1" variant="outlined" @click="reset" icon="mdi-restore"></v-btn>
            </v-row>
          </v-card-title>

        </template>

        <!-- Print choice modale -->
        <template v-slot:default="{ isActive }">
          <v-row justify="center" v-if="showPrintModale">
            <v-card style="width: 600px; max-width: 90%; max-height: calc(100vh - 40px); overflow-y: auto;">
              <v-card-title>
                Télécharger le plan de l'étage au format PDF
              </v-card-title>
              <v-card-item>
                <v-switch v-model="includeStudentNames" label="Inclure les élèves sur le plan" class="mb-4" color="success"></v-switch>
              </v-card-item>
              <v-card-actions>
                <v-row no-gutters>
                  <v-spacer></v-spacer>
                  <v-btn text="Annuler" @click="() => {
                    showPrintModale = false
                    isActive.value = false
                  }"></v-btn>
                  <v-btn text="Télécharger" @click="() => {
                    showPrintModale = false
                    isActive.value = false
                    $emit('print', includeStudentNames)
                  }"></v-btn>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-row>
        </template>
      </v-dialog>

      <v-card-item>

        <v-dialog>
          <template v-slot:activator="{ props: student }">
            <!-- Plan disposition -->
            <div class="mt-4" style="position: relative;">
              <v-col class="plan">
                <v-row no-gutters class="roomHeight">
                  <v-sheet
                    class="mx-1 px-1 rounded"
                    :class="bgColor(room)" 
                    v-for="(room, roomIndex) in topRooms" :key="room.id">
                    <v-select
                      v-if="modifiable"
                      :model-value="room.id"
                      item-title="shortName"
                      item-value="id"
                      :items="selectItems"
                      clearable
                      @update:model-value="(roomId: string) => roomChanged(roomId, roomIndex, 0)"
                    ></v-select>
                    <v-col v-if="!modifiable" style="position: relative;">
                      <div class="text-h6 text-center">{{ room.shortName }}</div>
                      <v-divider class="mt-2"></v-divider>
                    </v-col>
          
                    <v-list class="text-white">
                      <v-list-item
                        :class="listStudentClasses(room, room.students[studentIndex - 1])"
                        v-for="studentIndex in room.nbStudents" :key="studentIndex"
                        @click="() => selectedStudent = room.students[studentIndex - 1]"
                        v-bind="student"
                      >
                      <v-list-item-title v-if="room.students[studentIndex - 1]">
                        {{ room.students[studentIndex - 1].lastname + " " + room.students[studentIndex - 1].firstname }}
                      </v-list-item-title>
                      <v-list-item-title v-if="!room.students[studentIndex - 1]">
                        Place libre
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ room.students[studentIndex - 1]?.classname }}
                      </v-list-item-subtitle>
                      <template v-slot:append>
                        <v-icon v-if="room.students[studentIndex - 1]?.isRoomReferent">mdi-fire</v-icon>
                        <v-icon class="ml-1" v-if="room.students[studentIndex - 1]?.isPairMaster">mdi-certificate</v-icon>
                      </template>
                    </v-list-item>
                    </v-list>
                  </v-sheet>
                  <!-- Add a room -->
                  <v-sheet class="mx-1 bg-light-blue-lighten-1 rounded" v-if="modifiable">
                    <v-select
                      class="mx-1"
                      v-model="selectAdd1"
                      item-title="shortName"
                      item-value="id"
                      :items="selectItems"
                      @update:model-value="(roomId: string) => roomChanged(roomId, topRooms.length, 0)"
                    ></v-select>
                  </v-sheet>
                </v-row>
                <v-row no-gutters class="roomHeight corridor" style="position: relative; height: 50px; margin-top: -5px;">
                  <v-sheet v-for="n in corridorSize" class="px-1 bg-secondary" :key="n">
                    <div v-if="n === Math.ceil(corridorSize / 2)" class="text-white" :class="corridorSize % 2 === 0 ? 'text-end' : 'text-center'">Couloir</div>
                  </v-sheet>
                </v-row>
                <v-row no-gutters class="roomHeight" style="margin-top: -5px;">
                  <v-sheet
                    class="mx-1 px-1 rounded"
                    :class="bgColor(room)" 
                    v-for="(room, roomIndex) in bottomRooms" :key="room.id">
                    <v-select
                      v-if="modifiable"
                      :model-value="room.id"
                      item-title="shortName"
                      item-value="id"
                      :items="selectItems"
                      clearable
                      @update:model-value="(roomId: string) => roomChanged(roomId, roomIndex, 1)"
                    ></v-select>
                    <v-col v-if="!modifiable" style="position: relative;">
                      <div class="text-h6 text-center">{{ room.shortName }}</div>
                      <v-divider class="mt-2"></v-divider>
                    </v-col>

                    <v-list class="text-white">
                      <v-list-item
                        :class="listStudentClasses(room, room.students[studentIndex - 1])"
                        v-for="studentIndex in room.nbStudents" :key="studentIndex"
                        @click="() => selectedStudent = room.students[studentIndex - 1]"
                        v-bind="student"
                      >
                      <v-list-item-title v-if="room.students[studentIndex - 1]">
                        {{ room.students[studentIndex - 1].lastname + " " + room.students[studentIndex - 1].firstname }}
                      </v-list-item-title>
                      <v-list-item-title v-if="!room.students[studentIndex - 1]">
                        Place libre
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ room.students[studentIndex - 1]?.classname }}
                      </v-list-item-subtitle>
                      <template v-slot:append>
                        <v-icon v-if="room.students[studentIndex - 1]?.isRoomReferent">mdi-fire</v-icon>
                        <v-icon class="ml-1" v-if="room.students[studentIndex - 1]?.isPairMaster">mdi-certificate</v-icon>
                      </template>
                    </v-list-item>
                    </v-list>
                  </v-sheet>
                  <!-- Add a room -->
                  <v-sheet class="mx-1 bg-light-blue-lighten-1 rounded" v-if="modifiable">
                    <v-select
                      class="mx-1"
                      v-model="selectAdd2"
                      item-title="shortName"
                      item-value="id"
                      :items="selectItems"
                      @update:model-value="(roomId: string) => roomChanged(roomId, bottomRooms.length, 1)"
                    ></v-select>
                  </v-sheet>
                </v-row>
              </v-col>
            </div>
      
          </template>

          <!-- Student modale -->
          <template v-slot:default="{ isActive }">
            <v-row justify="center" v-if="selectedStudent">
              <v-card style="width: 600px; max-width: 90%; max-height: calc(100vh - 40px); overflow-y: auto;">
                <v-card-title>
                  <v-row no-gutters>
                    {{ profileStore.current?.role === 'admin' ? "Modifier élève" : "Détails de l'élève" }}
                    <v-spacer></v-spacer>
                    <v-btn icon="mdi-close" variant="outlined" @click="() => {
                      selectedStudent = undefined
                      isActive.value = false
                    }"></v-btn>
                  </v-row>
                </v-card-title>
                <v-card-item>
                  <StudentItem
                    :student="selectedStudent"
                    :readonly="profileStore.current?.role !== 'admin'" 
                    @update="(student: Student) => studentsStore.update(student)"
                  ></StudentItem>
                </v-card-item>
                <v-card-actions>
                  <v-row no-gutters>
                    <v-spacer></v-spacer>
                    <v-btn text="Fermer" @click="() => {
                      selectedStudent = undefined
                      isActive.value = false
                    }"></v-btn>
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-row>
          </template>
        </v-dialog>
      </v-card-item>
    </v-card>
  </v-container>
</template>
