import colors from 'vuetify/util/colors'

export const availableThemeColors = ["red", "pink", "purple", "deepPurple", "indigo", "blue", "lightBlue", "cyan", "teal", "green", "lightGreen", "lime", "yellow", "amber", "orange", "deepOrange", "brown", "blueGrey", "grey"]
export const themeColors = [
  {
    theme: "red",
    text: "Rouge",
    color: "red",
  },
  {
    theme: "pink",
    text: "Rose",
    color: "pink",
  },
  {
    theme: "purple",
    text: "Violet",
    color: "purple",
  },
  {
    theme: "deepPurple",
    text: "Violet 2",
    color: "deep-purple",
  },
  {
    theme: "indigo",
    text: "Indigo",
    color: "indigo",
  },
  {
    theme: "blue",
    text: "Bleu",
    color: "blue",
  },
  {
    theme: "lightBlue",
    text: "Bleu 2",
    color: "light-blue",
  },
  {
    theme: "cyan",
    text: "Cyan",
    color: "cyan",
  },
  {
    theme: "teal",
    text: "Turquoise",
    color: "teal",
  },
  {
    theme: "green",
    text: "Vert",
    color: "green",
  },
  {
    theme: "lightGreen",
    text: "Vert 2",
    color: "light-green",
  },
  {
    theme: "lime",
    text: "Citron",
    color: "lime",
  },
  {
    theme: "yellow",
    text: "Jaune",
    color: "yellow",
  },
  {
    theme: "amber",
    text: "Ambre",
    color: "amber",
  },
  {
    theme: "orange",
    text: "Orange",
    color: "orange",
  },
  {
    theme: "deepOrange",
    text: "Orange 2",
    color: "deep-orange",
  },
  {
    theme: "brown",
    text: "Marron",
    color: "brown",
  },
  {
    theme: "blueGrey",
    text: "Gris Bleu",
    color: "blue-grey",
  },
  {
    theme: "grey",
    text: "Gris",
    color: "grey",
  },
]
export const availableLightThemes: Map<string, {dark: boolean, colors: {primary: string, secondary: string}}> = new Map(
    availableThemeColors.map(color => [color, {
        dark: false,
        colors: {
            primary: (colors as any)[color].darken1,
            secondary: (colors as any)[color].lighten4,
            background: '#EEEEEE',
            surface: '#FFFFFF',
            'surface-bright': '#FFFFFF',
            'surface-light': '#EEEEEE',
            'surface-variant': '#424242',
            'on-surface-variant': '#EEEEEE',
        }
    }])
)
export const availableDarkThemes: Map<string, {dark: boolean, colors: {primary: string, secondary: string}}> = new Map(
    availableThemeColors.map(color => [`${color}-dark`, {
        dark: true,
        colors: {
            primary: (colors as any)[color].darken4,
            secondary: (colors as any)[color].darken1,
        }
    }])
)
export const defaultTheme = 'indigo'
