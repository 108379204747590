<script setup lang="ts">
import { onMounted, ref, type Ref } from 'vue';
import { useProfileStore } from '@/stores/profile';
import type { Worker } from '@/stores/types/worker';
import { useThemeStore } from '@/stores/themestore';
import { passwordRules } from '@/stores/password';
import { themeColors } from '@/stores/types/themes';

const profileStore = useProfileStore()
const profile: Ref<Worker | undefined> = ref()

const themeStore = useThemeStore()

const oldPassword: Ref<string> = ref("")
const newPassword: Ref<string> = ref("")
const newConfirmedPassword: Ref<string> = ref("")
const passwordMatchRule = [
  (v: string) => v === newPassword.value || 'Les mots de passe ne correspondent pas !'
]
const passwordChangeResultSnack: Ref<boolean> = ref(false)
const isPasswordChangeSuccess: Ref<boolean> = ref(true)
const isFormValid: Ref<boolean> = ref(false)

onMounted(async () => {
  profile.value = await profileStore.getProfile()
})

async function changePassword(): Promise<void> {
  isPasswordChangeSuccess.value = await profileStore.updatePassword(oldPassword.value, newPassword.value)
  passwordChangeResultSnack.value = true
  // clear password fields
  oldPassword.value = ""
}

</script>

<template>
  <main>
    <v-container>
      <v-col>
        <h1 class="text-center">Préférences</h1>
      </v-col>
      <v-card v-if="profile">
        <v-card-title>Profil</v-card-title>
        <v-form>
          <v-card-item>
            <v-text-field disabled label="Email" v-model="profile.email">
            </v-text-field>
            <v-text-field label="Prénom" v-model="profile.firstname" @update:model-value="profileStore.updateProfile(profile!!)">
            </v-text-field>
            <v-text-field label="Nom" v-model="profile.lastname"  @update:model-value="profileStore.updateProfile(profile!!)">
            </v-text-field>
          </v-card-item>
        </v-form>
      </v-card>
    </v-container>

    <v-container>
      <v-card>
        <v-card-title>
          Thèmes
        </v-card-title>
        <v-card-item>
          <v-col>
            <v-row no-gutters justify="center" align="center">
              <v-btn v-for="themeColor in themeColors" :key="themeColor.theme"
                :color="themeColor.color"
                @click="themeStore.setTheme(themeColor.theme)"
                class="ma-2"
                style="line-height: 1;"
              >
                {{ themeColor.text }}<br/>
                {{ themeStore.isThemeSelected(themeColor.theme) ? '(seléctionné)' : '' }}
              </v-btn>
            </v-row>
            <v-switch label="Thème sombre (pour réduire la fatigue)" color="primary" inset @click="themeStore.switchDarkMode()"
              v-model="themeStore.isDark"></v-switch>
          </v-col>
        </v-card-item>
      </v-card>
    </v-container>

    <v-container>
      <v-card v-if="profile">
        <v-card-title>Modifier mot de passe</v-card-title>
        <v-form v-model="isFormValid" fast-fail @submit.prevent="changePassword">
          <v-card-text>
            <v-text-field v-model="oldPassword" type="password" label="Ancien mot de passe"></v-text-field>
            <v-text-field v-model="newPassword" type="password" label="Nouveau mot de passe"
              :rules="passwordRules"></v-text-field>
            <v-text-field v-model="newConfirmedPassword" type="password" label="Confirmation nouveau mot de passe"
              :rules="passwordMatchRule"></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-row no-gutters class="ma-4">
              <v-spacer></v-spacer>
              <v-btn :disabled="!isFormValid" variant="flat" icon="mdi-check-bold" type="submit"></v-btn>
            </v-row>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-container>

    <v-container>
      <v-card>
        <v-card-item>
          <v-row no-gutters>
            <v-spacer></v-spacer>
            <v-btn prepend-icon="mdi-logout" @click="profileStore.logout()">Se déconnecter</v-btn>
            <v-spacer></v-spacer>
          </v-row>
        </v-card-item>
      </v-card>
    </v-container>

    <v-container>
      <v-dialog>
        <template v-slot:activator="{ props: deleteProfileDialog }">
          <v-card>
            <v-card-title class="text-red"><v-icon class="mr-4">mdi-alert</v-icon>Supprimer mon compte</v-card-title>
            <v-card-item>
              <v-row no-gutters>
                <v-spacer></v-spacer>
                <v-btn color="red" prepend-icon="mdi-delete-forever" v-bind="deleteProfileDialog">Supprimer définitivement</v-btn>
                <v-spacer></v-spacer>
              </v-row>
            </v-card-item>
          </v-card>
        </template>

        <template v-slot:default="{ isActive }">
          <v-row justify="center">
            <v-card style="max-width: 500px" title="Confirmer la suppression du compte" elevation="24">
              <v-card-text>
                <p class="mb-2">
                  Cette action est définitive et irréversible. Êtes-vous sûr de vouloir continuer ?
                </p>
              </v-card-text>
              <v-card-actions>
                <v-row no-gutters>
                  <v-spacer></v-spacer>
                  <v-btn text="Annuler" @click="() => {
                    isActive.value = false
                  }"></v-btn>
                  <v-btn text="Supprimer" @click="profileStore.deleteProfile().then(() => {
                    isActive.value = false
                  })"></v-btn>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-row>
        </template>
      </v-dialog>
    </v-container>

    <v-snackbar v-model="passwordChangeResultSnack" :color="isPasswordChangeSuccess ? 'success' : 'error'" rounded="pill"
      :timeout="5000" elevation="24">
      {{ isPasswordChangeSuccess ? "Mot de passe mis à jour avec Succès !" : "Mauvais ancien mot de passe..." }}

      <template v-slot:actions>
        <v-btn color="white" variant="text" @click="passwordChangeResultSnack = false" icon="mdi-close">
        </v-btn>
      </template>
    </v-snackbar>
  </main>
</template>
