import axios from 'axios'
import { defineStore } from 'pinia'
import { baseUrl } from './baseurl'
import { Student } from './types/student'
import { ref, type Ref } from 'vue'
import { AttendanceUtils } from '@/utils/attendance'
import dayjs from "dayjs";
import qs from "qs"

const urlStudents = `${baseUrl}/students`

export const useStudentsStore = defineStore('students', () => {
  const allStudents: Ref<Array<Student>> = ref([])
  const students: Ref<Array<Student>> = ref([])
  const selected: Ref<Student | undefined> = ref()

  async function all(includeRemoved = false): Promise<Array<Student>> {
    try {
      const studentsData = await axios.get(`${urlStudents}?includeRemoved=${includeRemoved}`)
      allStudents.value = studentsData.data as Array<Student>
      allStudents.value = allStudents.value.filter(x => x.id)
    } catch (error) {
      console.error(error)
      allStudents.value = []
    }
    return allStudents.value
  }

  async function findByCriteria(level: number, partialStudentName: string, noRoom: boolean, firstLetters: boolean): Promise<Array<Student>> {
    try {
      let url = `${urlStudents}?t=1`
      let filtered = false
      if (level > -1) {
        url += "&level=" + level
        filtered = true
      }
      if (partialStudentName) {
        url += "&name=" + partialStudentName
        filtered = true
      }
      if (noRoom) {
        url += "&roomId=none"
        filtered = true
      }
      if (firstLetters) {
        url += "&firstLetters=true"
      }
      const studentsData = await axios.get(url)
      students.value = studentsData.data as Array<Student>
      students.value = students.value.filter(x => x.id)

      // in case there is no filter, update all students variable
      if (!filtered) {
        allStudents.value = students.value
      }
    } catch (error) {
      console.error(error)
      students.value = []
    }
    return students.value
  }

  async function findByClass(level: number): Promise<Array<Student>> {
    try {
      const studentsData = await axios.get(`${urlStudents}?level=${level}`)
      students.value = studentsData.data as Array<Student>
      students.value = students.value.filter(x => x.id)
    } catch (error) {
      console.error(error)
      students.value = []
    }
    return students.value
  }

  async function findByRoom(roomId: string): Promise<Array<Student>> {
    try {
      const studentsData = await axios.get(`${urlStudents}?roomId=${roomId}`)
      students.value = studentsData.data as Array<Student>
      students.value = students.value.filter(x => x.id)
    } catch (error) {
      console.error(error)
      students.value = []
    }
    return students.value
  }

  async function findByName(namePart: string): Promise<Array<Student>> {
    try {
      const studentsData = await axios.get(`${urlStudents}?name=${namePart}`)
      students.value = studentsData.data as Array<Student>
      students.value = students.value.filter(x => x.id)
    } catch (error) {
      console.error(error)
      students.value = []
    }
    return students.value
  }
  
  async function findById(studentId: string): Promise<Student | undefined> {
    try {
      // load building
      const studentData = await axios.get(`${urlStudents}/${studentId}`)
      selected.value = studentData.data as Student
    } catch (error) {
      // FIXME: handle NOT FOUND
      console.error(error)
      selected.value = undefined
    }
    return selected.value
  }

  async function addNew(): Promise<Student> {
    try {
      const student = new Student()
      const studentCreated = await axios.post(urlStudents, student)
      selected.value = studentCreated.data as Student
      students.value = students.value.concat(selected.value)
    } catch (error) {
      console.error(error)
      throw new Error("Could not create a new user")
    }
    return selected.value
  }

  async function update(student: Student): Promise<void> {
    try {
      const data = await axios.put(
        `${urlStudents}/${student.id}`,
        student
      )
      student = data.data as Student

      // update refs
      selected.value = student
      students.value = students.value.map(x => x.id === student.id ? student : x)

    } catch (error) {
      // FIXME: handle NOT FOUND
      console.error(error)
    }
  }

  async function updateAttendance(student: Student): Promise<Student | undefined> {
    try {
      const data = await axios.put(
        `${urlStudents}/${student.id}/attendance`,
        student,
        {
          paramsSerializer: (params) => qs.stringify(params, {
            serializeDate: (date: Date) => dayjs(date).format() })
        }
      )
      student = data.data as Student
      AttendanceUtils.updateStats(student)
      return student
    } catch (error) {
      // FIXME: handle NOT FOUND
      console.error(error)
    }
  }

  async function remove(student: Student): Promise<void> {
    try {
      await axios.delete(
        `${urlStudents}/${student.id}`
      )
    } catch (error) {
      // FIXME: handle NOT FOUND
      console.error(error)
    }
    selected.value = undefined
    students.value = students.value.filter(x => x.id != student.id)
  }
 
  return { students, allStudents, selected, all, findByClass, findByRoom, findByName, findByCriteria, findById, addNew, update, updateAttendance, remove }
})
