import { AttendanceSlot, attendanceSlotsNotWednesday, attendanceSlotsWednesday } from "./attendanceslot"
import dayjs from "dayjs"

export class Attendance {
    studentRef: string
    date: dayjs.Dayjs

    slots: Array<AttendanceSlot> = []

    constructor(studentRef: string, date: dayjs.Dayjs) {
        this.studentRef = studentRef
        this.date = date.set('hour', 12) // 12 hours hack to prevent date issue with french time before midnight...

        const slots = date.day() == 3 ? attendanceSlotsWednesday : attendanceSlotsNotWednesday
        slots.forEach((startTime) => {
            this.slots.push(new AttendanceSlot(startTime))
        })
    }
}