<script setup lang="ts">
import { onMounted } from "vue";
import { useBuildingsStore } from '@/stores/buildings'
import { useProfileStore } from "@/stores/profile";

const profileStore = useProfileStore()
const buildingStore = useBuildingsStore()

onMounted(async () => {
  await buildingStore.all()
});
</script>

<style>
.floorBtn .v-btn__content {
  white-space: normal;
}
</style>

<template>
  <main>
    <v-col justify="center">
      <h1 class="text-center">Bâtiments / Etages</h1>
    </v-col>

    <v-container>
      <v-card class="m7-2">
        <v-card-title>
          <v-row no-gutters>
            <div>Etages par bâtiment</div>
            <v-spacer></v-spacer>
            <v-btn v-if="profileStore.current?.role === 'admin'" style="margin-bottom: 10px;" variant="outlined" icon="mdi-pencil" to="/admin/buildings"></v-btn>
          </v-row>
        </v-card-title>
        <v-card-item>
          <v-list>
            <v-list-item v-for="building in buildingStore.buildings.filter(x => !x.isArchived)" :key="building.id">
              <div>
                Bâtiment <b>{{ building.shortName }}</b>
              </div>
              <v-row no-gutters>
                <v-btn v-for="floor in building.floors.filter(x => !x.isArchived)" class="mr-1 mb-1 floorBtn" :key="floor.id"
                  rounded="xl"
                  :to="'/buildings/' + building.id + '/floors/' + floor.id + '/attendance'">
                  {{ floor.shortName }}
                </v-btn>
              </v-row>
              <v-divider :thickness="4" class="my-2"></v-divider>
              <div style="margin: 10px;"></div>
            </v-list-item>
          </v-list>
        </v-card-item>
      </v-card>
    </v-container>
  </main>
</template>
