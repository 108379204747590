export enum AttendanceStatus {
    NONE,
    ABSENT,
    STUDY,
    PRESENT,
    EVENING_INDOOR,
    AUTHORIZED_TO_LEAVE,
    EVENING_OUTDOOR,
}

export function getAttendanceStatusLabel(status: AttendanceStatus | undefined): string {
    switch (status) {
        case AttendanceStatus.ABSENT:
            return "Absent·e"
        case AttendanceStatus.STUDY:
            return "Etude"
        case AttendanceStatus.PRESENT:
            return "Présent·e"
        case AttendanceStatus.EVENING_INDOOR:
            return "Soirée intérieure"
        case AttendanceStatus.EVENING_OUTDOOR:
            return "Soirée extérieure"
        case AttendanceStatus.AUTHORIZED_TO_LEAVE:
            return "Sortie Autorisée"
        default:
            return ""
    }
}