import { defineStore } from 'pinia'
import { ref } from 'vue'

const COOKIES_PERFORMANCE_KEY = "COOKIES_CONSENT_PERFORMANCE"
const COOKIES_CONSENT_ANSWERED = "COOKIES_CONSENT_ANSWERED"

export const useCookiesStore = defineStore('cookies', () => {
  // True by default !
  const performance = ref(localStorage.getItem(COOKIES_PERFORMANCE_KEY) !== 'false')
  const isAnswered = ref(localStorage.getItem(COOKIES_CONSENT_ANSWERED) === 'true')
  
  function refuseAll() {
    performance.value = false
    save()
  }
  function acceptAll() {
    performance.value = true
    save()
  }
  function save() {
    localStorage.setItem(COOKIES_PERFORMANCE_KEY, "" + performance.value)
    localStorage.setItem(COOKIES_CONSENT_ANSWERED, "true")
    isAnswered.value = true
  }

  return { performance, refuseAll, acceptAll, isAnswered, save } 
})
