import { createApp } from 'vue'
import { createPinia } from 'pinia'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import DayJsAdapter from '@date-io/dayjs'

// Recaptcha
import { VueRecaptchaPlugin } from 'vue-recaptcha/head'

// sentry
import * as Sentry from "@sentry/vue";

// Translations provided by Vuetify
import { fr } from 'vuetify/locale'

import { md2 } from 'vuetify/blueprints'

import App from './App.vue'
import router from './router'

// --- DAYJS config
import dayjs from 'dayjs'
import 'dayjs/locale/fr'
dayjs.locale('fr')

import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

import localizedFormat from 'dayjs/plugin/localizedFormat'
dayjs.extend(localizedFormat)

import weekOfYear from 'dayjs/plugin/weekOfYear'
dayjs.extend(weekOfYear)

import isToday from 'dayjs/plugin/isToday'
dayjs.extend(isToday)

// Themes
import { availableDarkThemes, availableLightThemes, defaultTheme } from './stores/types/themes'

// Create vuetify instance
const vuetify = createVuetify({
  components,
  directives,
  blueprint: md2,
  locale: {
    locale: 'fr',
    messages: { fr }
  },
  date: {
    adapter: DayJsAdapter,
    locale: {
      fr: dayjs.locale('fr'),
    }
  },
  theme: {
    defaultTheme: defaultTheme,
    themes: {...Object.fromEntries(availableLightThemes.entries()), ...Object.fromEntries(availableDarkThemes.entries())},
  }
})

const app = createApp(App)

app.use(vuetify)
app.use(createPinia())
app.use(router)
app.use(VueRecaptchaPlugin, {
  v3SiteKey: import.meta.env.VITE_RECAPTCHA_SITE_KEY_V3,
})

// Sentry configuration
Sentry.init({
  // Completely deactivate sentry when Perform
  enabled: process.env.NODE_ENV !== 'development',
  app,
  release: "boarding_school@" + __APP_VERSION__,
  dsn: "https://fb26455c8b86b5fc59708a8a3af09872@o4506990433796096.ingest.us.sentry.io/4506990438711296",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      beforeAddRecordingEvent: (event) => {
        // Do not capture events if user did not consent
        if (localStorage.getItem("COOKIES_CONSENT_PERFORMANCE") === 'false') {
          return null;
        }
        return event;
      }
    }),
  ],
  // Do not send events if user did not consent
  tracesSampler: () => {
    if (localStorage.getItem("COOKIES_CONSENT_PERFORMANCE") === 'false') {
      // Drop this transaction, by setting its sample rate to 0%
      return 0;
    } else {
      // Default sample rate for all others (replaces tracesSampleRate)
      return 1;
    }
  },
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https:\/\/lycee-internat\.ddns\.net/, /^https:\/\/legta-auzeville\.mon-internat\.com/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.mount('#app')
