import { defineStore } from 'pinia'
import { ref, type Ref } from 'vue'
import { useTheme } from 'vuetify'

export const useThemeStore = defineStore('theme', () => {
    const isDark: Ref<boolean> = ref(localStorage.getItem("isDark") == "true")
    const currentTheme: Ref<string> = ref(localStorage.getItem("currentTheme") || 'indigo')
    
    const theme = useTheme();

    setTheme(localStorage.getItem("currentTheme") || 'indigo')

    function setTheme(themeStr: string) {
        localStorage.setItem("currentTheme", themeStr)
        currentTheme.value = isDark.value ? `${themeStr}-dark` : themeStr
        theme.global.name.value = currentTheme.value
    }

    function isThemeSelected(themeColor: string): boolean {
        return currentTheme.value.replace("-dark", "") === themeColor
    }      

    function switchDarkMode() {
        isDark.value = !isDark.value
        // persist it in localStorage
        localStorage.setItem("isDark", `${isDark.value}`)
        setTheme(localStorage.getItem("currentTheme") || 'indigo')        
    }

    return { isDark, switchDarkMode, isThemeSelected, currentTheme, setTheme }
})
