<script setup lang="ts">
import { useBuildingsStore } from "@/stores/buildings";
import { useFloorsStore } from "@/stores/floors";
import { useRoomsStore } from "@/stores/rooms";
import { onMounted, ref, type Ref } from "vue";
import { useRoute } from 'vue-router';
import RoomAdminItem from '@/components/RoomAdminItem.vue'
import AttendanceItem from '@/components/AttendanceItem.vue'
import DateDisplay from "@/components/DateDisplay.vue";
import { useStudentsStore } from "@/stores/students";
import type { Student } from "@/stores/types/student";
import dayjs from "dayjs";
import type { Room } from "@/stores/types/room";
import { useProfileStore } from "@/stores/profile";
import type { AttendanceStatus } from "@/stores/types/attendancestatus";
import { Attendance } from "@/stores/types/attendance";
import { AttendanceSlot } from "@/stores/types/attendanceslot";

const route = useRoute()

const roomStore = useRoomsStore()
const buildingStore = useBuildingsStore()
const floorStore = useFloorsStore()
const studentStore = useStudentsStore()
const profileStore = useProfileStore()

const buildingId = route.params.buildingId as string
const floorId = route.params.floorId as string
const roomId = route.params.roomId as string

const allStudents: Ref<Array<Student>> = ref([])
const today = dayjs(new Date())
const currentDay: Ref<dayjs.Dayjs> = ref(today)

const room: Ref<Room | undefined> = ref()
const breadcrumbsItems = ref()

onMounted(async () => {
  await buildingStore.load(buildingId)
  await floorStore.load(floorId)
  allStudents.value = await studentStore.all()
  await roomStore.load(roomId)
  await loadAttendance(roomId)

  breadcrumbsItems.value = [
    {
      title: 'Bâtiments',
      disabled: false,
      to: '/buildings',
    },
    {
      title: `Bâtiment ${buildingStore.selected?.shortName}/${floorStore.selected?.shortName}`,
      disabled: false,
      to: '/buildings/' + buildingId + '/floors/' + floorId + '/rooms/',
    },
    {
      title: `Chambre ${room.value?.shortName}`,
      disabled: true,
      to: '/buildings/' + buildingId + '/floors/' + floorId + '/rooms/details/' + roomId,
    }
  ]
});

async function loadAttendance(id: string | undefined) {
  if (!id) return
  room.value = await roomStore.loadWithWeekAttendance(id, currentDay.value)
}

async function changeDay(newDay: dayjs.Dayjs) {
  const previousDay = currentDay.value
  currentDay.value = newDay
  if (previousDay.week() !== currentDay.value.week()) {
    await loadAttendance(room.value?.id)
  }
}

function updateStudent(student: Student, slotStartTime: string, status: AttendanceStatus) {
  // create attendance if it does not exist
  let attendance = student.attendances.find(x => x.date.format("YYYY-MM-DD") === currentDay.value.format("YYYY-MM-DD"))
  if (!attendance) {
    attendance = new Attendance(student.id, currentDay.value)
    student.attendances.push(attendance)
  }
  // create slot if it does not exist
  let slot = attendance.slots.find(x => x.startTime === slotStartTime)
  if (!slot) {
    slot = new AttendanceSlot(slotStartTime)
    attendance.slots.push(slot)
  }
  // update the given status
  slot.status = status

  // update the student on server side
  studentStore.updateAttendance(student).then((student) => {
    // ensure using modified student
    if (room.value)
      room.value.students = room.value.students.map(x => x.id === student?.id ? student : x)
  })
}

async function nextRoom() {
  if (room.value)
    room.value = roomsWithAttendance()[(roomsWithAttendance().findIndex(x => x.id === room.value?.id) + 1) % roomsWithAttendance().length]
  await loadAttendance(room.value?.id)
}

async function previousRoom() {
  if (room.value) {
    let index = roomsWithAttendance().findIndex(x => x.id === room.value?.id)
    let nbRooms = roomsWithAttendance().length
    if (index === undefined ||nbRooms === undefined) return
    index--
    if (index < 0) index = nbRooms - 1
    room.value = roomsWithAttendance()[index]
  }
  await loadAttendance(room.value?.id)
}

function roomsWithAttendance() {
  return floorStore.selected?.rooms.filter(x => x.nbStudents > 0) || []
}

async function updateRoom(room: Room) {
  await roomStore.update(room)
}

</script>

<template>
  <main>
    <v-breadcrumbs :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon icon="mdi-chevron-right"></v-icon>
      </template>
    </v-breadcrumbs>

    <v-col justify="center">
      <h1 class="text-center">Bâtiment {{ buildingStore.selected?.shortName }}</h1>
      <h2 class="text-center">{{ floorStore.selected?.shortName }}</h2>
      <v-row no-gutters justify="center" align="center">
        <v-btn icon="mdi-arrow-left" variant="flat" @click="previousRoom()"></v-btn>
        <h3 class="mx-2">Chambre {{ room?.shortName }}</h3>
        <v-btn icon="mdi-arrow-right" variant="flat" @click="nextRoom()"></v-btn>
      </v-row>
    </v-col>

    <DateDisplay :currentDay="currentDay" @change="(newDay: dayjs.Dayjs) => changeDay(newDay)"></DateDisplay>

    <!-- :disabled="dayjs(new Date()).format('YYYY-MM-DD') !== currentDay.format('YYYY-MM-DD')" -->
    <v-container>
      <AttendanceItem
        v-if="room"
        class="text-center"
        :room="room"
        :currentDay="currentDay"
        @updateStudent="(student: Student, slotStartTime: string, status: AttendanceStatus) => updateStudent(student, slotStartTime, status)">
      </AttendanceItem>
    </v-container>

    <v-container v-if="profileStore.current?.role == 'admin'">
      <v-card>
        <v-card-title>
          <v-row no-gutters>
            <v-icon color="primary" icon="mdi-shield"></v-icon>
            <div class="mx-2">Modifier la chambre</div>
          </v-row>
        </v-card-title>
        <v-card-text>
          <RoomAdminItem
            v-if="roomStore.selected"
            :buildingId="buildingId"
            :room="roomStore.selected"
            :allStudents="allStudents"
            archivable
            @update="(room) => updateRoom(room)">
          </RoomAdminItem>
        </v-card-text>
      </v-card>
    </v-container>
  </main>
</template>
