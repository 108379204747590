<script setup lang="ts">
import { useProfileStore } from '@/stores/profile'
import CookiesConsentBanner from '@/components/CookiesConsentBanner.vue'

const profileStore = useProfileStore()
</script>

<style>
.subtitle {
  font-weight: bold;
  font-size: 1.2em;
}
.mention {
  font-size: 1em;
  text-align: justify;
  text-justify: inter-word;
}
</style>

<template>
  <main>
    <v-container v-if="!profileStore.current">
      <v-row no-gutters>
        <v-spacer></v-spacer>
        <v-btn append-icon="mdi-login" to="/login" variant="text">Retour à la page de connection</v-btn>
      </v-row>
    </v-container>
    <v-container>
      <h1 text-center>Confidentialité</h1>
      <div class="paragraph my-4">
        <div class="subtitle">
          Déclaration de confidentialité
        </div>
        <div class="mention">
          Bienvenue dans la Déclaration de confidentialité « Lycée Internat ». C’est là que nous décrivons la façon dont nous gérons vos « données personnelles », qui sont des informations directement liées ou qui peuvent être liées à vous ou aux élèves. Cette déclaration s’applique aux données personnelles que « Lycée Internat », traite en tant que « contrôleur de données » lorsque vous interagissez avec ce site Web.
        </div>
      </div>

      <div class="paragraph my-4">
        <div class="subtitle">
          Les données personnelles que nous collectons
        </div>
        <div class="mention">
          Nous collectons des données personnelles concernant 2 publics distincts:
          <ul>
            <li class="ml-8">Les données vous concernant en tant que membre de l'équipe éducative. Celles-ci sont limitées à vos nom, prénom et email.</li>
            <li class="ml-8">Les données concernant les élèves hébergés à l'internat. Celles-ci sont limitées au strict minimum nécessaire afin de garantir la sécurité des élèves au sein de l'internat et de permettre le meilleur accompagnement possible des élèves tout au long de leur présence au sein de l'internat.</li>
          </ul>
        </div>
      </div>

      <div class="paragraph my-4">
        <div class="subtitle">
          Caractère de la collecte de données
        </div>
        <div class="mention">
          Les informations recueillies sur ce site sont uniquement accessibles pas l'équipe éducative du Lycée d'Enseignement Générale et Technologique Agricole (LEGTA) de la cité des sciences vertes pour la gestion des élèves de l'internat. La base légale du traitement est la gestion d'un internat, et des élèves y résidant, par les membres de l'équipe éducative (présence des élèves, absences des élèves, aide aux devoirs, rapports de conseil de classe, répartition par chambre).<br/>
          Les données collectées sont communiquées aux seuls membres de l'équipe éducative du lycée Lycée d'Enseignement Générale et Technologique Agricole (LEGTA) de la cité des sciences vertes.
        </div>
      </div>

      <div class="paragraph my-4">
        <div class="subtitle">
          Durée de conservation des données
        </div>
        <div class="mention">
          Les données sont conservées pendant la durée nécessaire à la gestion de l'internat. Les durées de conservation sont les suivantes:
          <ul>
            <li class="ml-8">Pour les élèves, les données sont conservées pendant la durée de la présence de l'élève à l'internat. Elles sont supprimées dès que l'élève quitte l'établissement ou l'internat.</li>
            <li class="ml-8">Pour les membres de l'équipe éducative, les données sont conservées pendant la durée de leur contrat d'éducateur ou de CPE. Elles sont supprimées dès qu'il quitte l'établissement ou qu'il/elle ne s'occupe plus de l'internat.</li>
          </ul>

          Dans tous les cas, une suppression automatique au bout de 3 ans est programmée pour les données des élèves et des membres de l'équipe éducative qui ne seraient pas modifiées.
          Ainsi:
          <ul>
            <li class="ml-8">Si un membre de l'équipe éducative ne se connecte pas pendant 3 ans, ses données seront automatiquement supprimées à ce terme.</li>
            <li class="ml-8">Si un élève n'est pas déplacé de chambre, n'a pas sa classe modifiée et ne subit aucun autre changement, au bout de 3 ans, ses données seront automatiquement supprimées.</li>
          </ul>

        </div>
      </div>

      <div class="paragraph my-4">
        <div class="subtitle">
          Accès à vos données personnelles
        </div>
        <div class="mention">
          Vous pouvez accéder aux données vous concernant (élève ou équipe éducative), les rectifier, demander leur effacement ou exercer votre droit à la limitation du traitement de vos données. Vous pouvez également exercer votre droit à la portabilité de vos données en addressant un email à la Conseillère Principale d'Education du lycée.
        </div>
      </div>

      <div class="paragraph my-4">
        <div class="subtitle">
          Gestion des cookies et des trackers
        </div>
        <div class="mention">
          Un cookie afin de savoir si vous êtes connecté ou non est utilisé. Celui-ci ne peut être coupé, votre seule action possible est de quitter le site.
          D'autre part, des informations d'audience ainsi que les erreurs sont envoyées à <a href="https://sentry.io">Sentry.io</a> sans aucune donnée personnelle afin de permettra l'amélioration continue du site web dans le respect de vos données personnelles. Ce tracker, quant à lui, peut être désactivé.
          Vous pouvez régler les cookies depuis le panneau ci-dessous.
        </div>
        <CookiesConsentBanner class="my-4"></CookiesConsentBanner>
      </div>

      <div class="paragraph my-4">
        <div class="subtitle">
          Faire valoir vos droits
        </div>
        <div class="mention">
          Consultez le site <a href="https://cnil.fr">cnil.fr</a> pour plus d’informations sur vos droits.<br/>
          Pour exercer ces droits ou pour toute question sur le traitement de vos données dans ce dispositif, vous pouvez contacter (le cas échéant, notre délégué à la protection des données ou le service chargé de l’exercice de ces droits) : <a href="mailto:contact.moninternat@gmail.com">Contact</a><br/>
          Si vous estimez, après nous avoir contactés, que vos droits « Informatique et Libertés » ne sont pas respectés, vous pouvez adresser une réclamation à la CNIL.<br/>
        </div>
      </div>
    </v-container>
  </main>
</template>
