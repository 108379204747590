import dayjs from 'dayjs';

export class Comment {
    id: string = ''
    date: string
    floorRef: string

    // Content
    comment: string = ''
    signature: string = ''
    workerRef: string = ''

    constructor(floorId: string, date: string = dayjs(new Date()).format("YYYY-MM-DD")) {
        this.floorRef = floorId
        this.date = date
    }
}
