<script setup lang="ts">
import {ref, type PropType, onUpdated, onMounted} from 'vue'
import {Comment} from '@/stores/types/comment'
import { useProfileStore } from '@/stores/profile';

const props = defineProps({
  comment: {type: Object as PropType<Comment>, required: true},
  observationTitle: {type: String as PropType<string>, default: 'Observation'},
  editable: {type: Boolean as PropType<boolean>, default: false},
})
const emit = defineEmits(['update', 'sign', 'previous', 'next'])

const profileStore = useProfileStore()

const commentTxt = ref(props.comment?.comment)
const signature = ref(props.comment?.signature)

onMounted(() => {
  refresh()
})
onUpdated(() => {
  refresh()
})

function refresh() {
  commentTxt.value = props.comment?.comment
  signature.value = props.comment?.signature
}

function sign() {
  signature.value = profileStore.current?.lastname + ' ' + profileStore.current?.firstname
  emit('sign', signature)
}
</script>

<template>
  <v-card v-if="comment" class="my-2">
    <v-card-item>
      <v-label>{{ observationTitle }}</v-label>
      <v-textarea :disabled="!editable" v-model="commentTxt" @update:model-value="$emit('update', commentTxt)"></v-textarea>
    </v-card-item>
    <v-card-item>
      <v-label>Signature</v-label>
      <v-row no-gutters align="center" justify="center">
        <v-btn v-if="editable && profileStore.current?.lastname" class="ma-md-2 ma-lg-2 ma-sm-2" variant="flat" @click="sign()" prepend-icon="mdi-draw">Signer</v-btn>
        <v-text-field v-model="signature" @update:model-value="emit('sign', signature)"></v-text-field>
      </v-row>
    </v-card-item>
  </v-card>
</template>
