import { createRouter, createWebHistory } from 'vue-router'
import BuildingsView from '@/views/BuildingsView.vue'
import ProfileView from '@/views/ProfileView.vue'
import FloorsView from '@/views/FloorsView.vue'
import LoginView from '@/views/LoginView.vue'
import RoomView from '@/views/RoomView.vue'
import StudentsView from '@/views/StudentsView.vue'
import PrivacyView from '@/views/PrivacyView.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      alias: '/buildings',
      name: 'home',
      component: BuildingsView
    },
    {
      path: '/buildings/:buildingId/floors/:floorId/:tabId',
      name: 'floor',
      component: FloorsView
    },
    {
      path: '/buildings/:buildingId/floors/:floorId/rooms/details/:roomId',
      name: 'room',
      component: RoomView
    },
    {
      path: '/students',
      name: 'students',
      component: StudentsView
    },
    {
      path: '/profile',
      name: 'profile',
      component: ProfileView
    },
    {
      path: '/login',
      name: 'login',
      component: LoginView
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: PrivacyView
    },
    // Admin (lazy loading)
    {
      path: '/admin/buildings',
      name: 'buildingAdmin',
      component: () => import('../views/admin/BuildingsAdminView.vue')
    },
    {
      path: '/admin/buildings/:buildingId/floors/:floorId/rooms',
      name: 'roomsAdmin',
      component: () => import('../views/admin/FloorAdminView.vue')
    },
    {
      path: '/admin/workers',
      name: 'workers',
      component: () => import('../views/admin/GlobalAdminView.vue')
    },
    {
      path: '/admin/classnames',
      name: 'classnames',
      component: () => import('../views/admin/ClassnamesAdminView.vue')
    },
  ]
})

export default router
