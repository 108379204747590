import type { Student } from "./student"

export class Loan {
	id:         string = ''
	objectName: string = ''
	startTime: Date = new Date()

	// Handle quantity
	quantity: number = 0
	nbBorrowed: number = 0

	// Loan is associated to a floor
	floorRef: string = ''

	// Can be associated to multiple students
	students: Array<Student> = []
}
