import { Attendance } from "@/stores/types/attendance"
import { AttendanceSlot, SLOT1, SLOT2, SLOT3, attendanceSlotsNotWednesday, attendanceSlotsWednesday } from '@/stores/types/attendanceslot';
import { AttendanceStatus } from "@/stores/types/attendancestatus"
import type { Student } from "@/stores/types/student"
import dayjs, { Dayjs } from "dayjs"

interface Statable {
  attendances: Array<Attendance>
  // stats
  nbStudies: number
  nbAbsences: number
  nbPresent: number
  nbEvening: number
  nbAuthorizedToLeave: number
  nbTotalPossibleStudies: number
}

export class AttendanceUtils {
  
  static AttendanceStatusLabels = new Array<{value: AttendanceStatus, title: string, disabled: boolean}>(
    { value: AttendanceStatus.NONE, title: "", disabled: false },
    { value: AttendanceStatus.ABSENT, title: "Absent·e", disabled: false },
    { value: AttendanceStatus.STUDY, title: "Etude", disabled: false },
    { value: AttendanceStatus.PRESENT, title: "Présent·e", disabled: false },
    { value: AttendanceStatus.EVENING_INDOOR, title: "Soirée intérieure", disabled: false },
    { value: AttendanceStatus.EVENING_OUTDOOR, title: "Soirée extérieure", disabled: false },
    { value: AttendanceStatus.AUTHORIZED_TO_LEAVE, title: "Sortie Autorisée", disabled: false },
  )

  static getTitleForStatus(status: AttendanceStatus | undefined): string | undefined {
    return AttendanceUtils.AttendanceStatusLabels.find(x => x.value === status)?.title
  }

  static updateStats(item: Statable) {
    let nbStudies = 0
    let nbPresent = 0
    let nbAbsences = 0
    let nbEvening = 0
    let nbAuthorizedToLeave = 0
    if (!item.attendances) {
      return
    }
    item.attendances.forEach(attendance => {
      const dateAttendance = dayjs(attendance.date)
      // We don't count wednesday
      if (dateAttendance.day() !== 3) {
        attendance.slots.forEach(slot => {
          // we don't count 3rd slot
          if (slot.startTime === SLOT3) return

          switch(slot.status) {
            case AttendanceStatus.PRESENT:
              nbPresent++
              break
            case AttendanceStatus.STUDY:
              nbStudies++
              break
            case AttendanceStatus.ABSENT:
              nbAbsences++
              break
            case AttendanceStatus.EVENING_INDOOR:
            case AttendanceStatus.EVENING_OUTDOOR:
              nbEvening++
              break
            case AttendanceStatus.AUTHORIZED_TO_LEAVE:
              nbAuthorizedToLeave++
              break
            default:
              break
          }
        })
      }
    })
    item.nbStudies = nbStudies
    item.nbAbsences = nbAbsences
    item.nbPresent = nbPresent
    item.nbEvening = nbEvening
    item.nbAuthorizedToLeave = nbAuthorizedToLeave
    item.nbTotalPossibleStudies = nbStudies + nbEvening
  }

  static studentAttendanceSlots(day: Dayjs | undefined): Array<string> {
    return day?.day() === 3 ? attendanceSlotsWednesday : attendanceSlotsNotWednesday
  }

  static getStatus(student: Student, slotStartTime: string, day: Dayjs | undefined): AttendanceSlot {
    const attendances = student.attendances.
      find((x) => x.date.format('YYYY-MM-DD') === day?.format('YYYY-MM-DD'))
    if (attendances) {
      return attendances.slots.find(x => x.startTime === slotStartTime) || new AttendanceSlot(slotStartTime)
    }
    return new AttendanceSlot(slotStartTime)
  }

  static getDefaultAttendanceStatuses(slotStartTime: string): Array<{value: AttendanceStatus, title: string, disabled: boolean, subtitle: string}> {
    const statuses: Array<{value: AttendanceStatus, title: string, disabled: boolean, subtitle: string}> = JSON.parse(JSON.stringify(AttendanceUtils.AttendanceStatusLabels))
    // at 1st and 2nd slot, only studies can be globally set by default
    let filteredStatus = [AttendanceStatus.STUDY]
    // at 3rd slot, only Present can be globally set by default
    if (slotStartTime === SLOT3) {
      filteredStatus = [AttendanceStatus.PRESENT]
    }
    filteredStatus.push(AttendanceStatus.ABSENT)
    return statuses.filter((x) => filteredStatus.includes(x.value))!!
  }

  static getEmptyAttendanceStatus(): {value: AttendanceStatus, title: string, disabled: boolean, subtitle: string} {
    const statuses: Array<{value: AttendanceStatus, title: string, disabled: boolean, subtitle: string}> = JSON.parse(JSON.stringify(AttendanceUtils.AttendanceStatusLabels))
    return statuses.find(x => x.value === AttendanceStatus.NONE)!!
  }

  static getAttendanceStatuses(student: Student, slotStartTime: string, day: Dayjs | undefined, rulesEnabled: boolean = true): Array<{value: AttendanceStatus, title: string, disabled: boolean, subtitle: string}> {
    let statuses: Array<{value: AttendanceStatus, title: string, disabled: boolean, subtitle: string}> = JSON.parse(JSON.stringify(AttendanceUtils.AttendanceStatusLabels))

    // at 3rd slot, only Present and Absent
    if (slotStartTime == SLOT3) {
      return statuses.filter((x) => x.value === AttendanceStatus.NONE || x.value === AttendanceStatus.ABSENT || x.value === AttendanceStatus.PRESENT)
    }
    // In other slots, no Present status
    else {
      statuses = statuses.filter((x) => x.value!= AttendanceStatus.PRESENT)
    }

    // If rules are disabled, return immediatly statuses
    if (!rulesEnabled) {
      return statuses
    }

    // No evening at 18:10 for Students before "Terminal"
    // No evening at 20:15 on wednesday for everyone
    if (
      ((student.level < 2 || student.isLikeLevel0) && slotStartTime === SLOT1) ||
      (day?.day() === 3 && slotStartTime === SLOT2)
      ) {
      const eveningStatuses = statuses.filter(x => x.value === AttendanceStatus.EVENING_INDOOR || x.value === AttendanceStatus.EVENING_OUTDOOR)
      for (const eveningStatus of eveningStatuses) {
        eveningStatus.disabled = true
        eveningStatus.subtitle = "Etude obligatoire"
      }
    }
    // For level 0 => 1 evening by week maximum
    else if ((student.level == 0 || student.isLikeLevel0) && student.nbEvening >= 1) {
      const eveningStatuses = statuses.filter(x => x.value === AttendanceStatus.EVENING_INDOOR || x.value === AttendanceStatus.EVENING_OUTDOOR)
      for (const eveningStatus of eveningStatuses) {
        eveningStatus.disabled = true
        eveningStatus.subtitle = "Soirée déjà prise dans la semaine"
      }
    }

    return statuses
  }
}
