import axios from 'axios'
import { defineStore } from 'pinia'
import { ref, type Ref } from 'vue'
import { baseUrl } from './baseurl'
import type { Loan } from './types/loan'
import type { Student } from './types/student'

export const useLoanStore = defineStore('loan', () => {
  const allLoans: Ref<Array<Loan>> = ref([])

  async function all(): Promise<Array<Loan>> {
    try {
      const data = await axios.get(`${baseUrl}/loans`)
      allLoans.value = data.data as Array<Loan>
      return allLoans.value
    } catch (error) {
      console.log(error)
    }
    return []
  }

  async function getByFloorId(floorId: string): Promise<Array<Loan>> {
    try {
      const data = await axios.get(`${baseUrl}/floors/${floorId}/loans`)
      return data.data as Array<Loan>
    } catch (error) {
      console.log(error)
    }
    return []
  }

  async function get(floorId: string, loanId: string): Promise<Loan|undefined> {
    try {
      const data = await axios.get(`${baseUrl}/floors/${floorId}/loans/${loanId}`)
      return data.data as Loan
    } catch (error) {
      console.log(error)
    }
  }

  async function create(floorId: string, loan: Loan): Promise<Loan> {
    try {
      const data = await axios.post(`${baseUrl}/floors/${floorId}/loans`, loan)
      return data.data as Loan
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  async function update(floorId: string, loan: Loan): Promise<Loan> {
    try {
      const data = await axios.put(`${baseUrl}/floors/${floorId}/loans/${loan.id}`, loan)
      return data.data as Loan
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  async function remove(floorId: string, loanId: string): Promise<void> {
    await axios.delete(`${baseUrl}/floors/${floorId}/loans/${loanId}`)
  }

  async function associateLoanToStudent(student: Student, loan: Loan, associated: boolean): Promise<void> {
    if (associated) {
      await axios.put(`${baseUrl}/floors/${loan.floorRef}/loans/${loan.id}/students/${student.id}`)
      student.loans = student.loans.concat(loan)
      loan.students = loan.students.concat(student)
    }
    else {
      await axios.delete(`${baseUrl}/floors/${loan.floorRef}/loans/${loan.id}/students/${student.id}`)
      student.loans = student.loans?.filter(x => x.id != loan.id)
      loan.students = loan.students.filter(x => x.id != student.id)
    }
  }

  return { allLoans, all, getByFloorId, get, create, update, remove, associateLoanToStudent }
})