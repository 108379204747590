<script setup lang="ts">
import { useFloorsStore } from "@/stores/floors";
import { onMounted, ref, type Ref } from "vue";
import { useStudentsStore } from "@/stores/students";
import type { Student } from "@/stores/types/student";
import { Dayjs } from "dayjs";
import type { Floor } from "@/stores/types/floor";
import AttendanceItem from "@/components/AttendanceItem.vue";
import { AttendanceStatus } from "@/stores/types/attendancestatus";
import type { Room } from "@/stores/types/room";
import { Attendance } from "@/stores/types/attendance";
import { AttendanceSlot } from "@/stores/types/attendanceslot";
import type { PropType } from "vue";
import { AttendanceUtils } from "@/utils/attendance";
import { useAttendancesStore } from "@/stores/attendance";
import { useBuildingsStore } from "@/stores/buildings";
import { watch } from "vue";

const buildingStore = useBuildingsStore()
const floorStore = useFloorsStore()
const studentStore = useStudentsStore()
const attendanceStore = useAttendancesStore()

const props = defineProps({
  buildingId: {type: String, required: true},
  floorId: {type: String, required: true},
  previousDay: {type: Object as PropType<Dayjs>},
  currentDay: {type: Object as PropType<Dayjs>, required: true},
})

const floor: Ref<Floor | undefined> = ref()
const loading = ref(true)
const updating = ref(false)

const dialogGlobalModifVisible = ref(false)
const selectedSlotStartTime: Ref<string|undefined> = ref()
const selectedSlotStatus: Ref<AttendanceStatus|undefined> = ref()

const rulesEnabled = ref(true)

onMounted(async() => {
  await loadAttendance()
})

watch(() => props.currentDay, async () => {
  await loadAttendance()
})

async function loadAttendance() {
  // only update if we changed of week
  if (props.currentDay.week() === props.previousDay?.week()) return

  loading.value = true
  floor.value = await floorStore.loadWithAttendanceDate(props.floorId, props.currentDay)
  loading.value = false
}

async function updateStudent(room: Room, student: Student, slotStartTime: string, status: AttendanceStatus) {
  // create attendance if it does not exist
  let attendance = student.attendances.find(x => x.date.format("YYYY-MM-DD") === props.currentDay.format("YYYY-MM-DD"))
  if (!attendance) {
    attendance = new Attendance(student.id, props.currentDay)
    student.attendances.push(attendance)
  }
  // create slot if it does not exist
  let slot = attendance.slots.find(x => x.startTime === slotStartTime)
  if (!slot) {
    slot = new AttendanceSlot(slotStartTime)
    attendance.slots.push(slot)
  }
  // update the given status
  slot.status = status

  // update the student on server side
  const updatedStudent = await studentStore.updateAttendance(student)

  // ensure using modified student
  room.students = room.students.map(x => x.id === updatedStudent?.id ? updatedStudent : x)
}

async function updateAllStudents(slotStartTime: string | undefined, status: AttendanceStatus | undefined) {
  if (!floor.value || !slotStartTime || status === undefined) return;
  updating.value = true
  // Batch update all the floor attendance
  await attendanceStore.batchUpdate(floor.value, props.currentDay.set('hour', 12), slotStartTime, status)
  // Refresh the whole list now
  floor.value = await floorStore.loadWithAttendanceDate(props.floorId, props.currentDay)
  updating.value = false
}

function resetDialogAttendanceGlobal() {
  dialogGlobalModifVisible.value = false
  selectedSlotStartTime.value = undefined
  selectedSlotStatus.value = undefined
}

function scrollTop() {
  window.scrollTo({top: 0, behavior: 'smooth'})
}
</script>

<template>
  <v-btn icon="mdi-printer" variant="flat" style="z-index: 12; position: absolute; right: 0; top: 0;" @click="attendanceStore.downloadAttendancePdf(buildingId, floorId, currentDay, buildingStore.selected?.shortName, floorStore.selected?.shortName)"></v-btn>
  
  <v-card>
    <v-card-title>
      Appel pour tous les élèves de l'étage
    </v-card-title>
    
    <v-card-item>
      <v-row noGutters justify="end">
        <v-col v-for="(slotStartTime, slotIndex) in AttendanceUtils.studentAttendanceSlots(currentDay)" :key="slotIndex" class="my-2 px-1">
          <th class="text-center mb-4" style="width: 100%; display: block; font-weight: 500;">{{ slotStartTime.replace(":", "h") }}</th>

            <v-select style="min-width: 150px"
            :items="[AttendanceUtils.getEmptyAttendanceStatus()].concat(AttendanceUtils.getDefaultAttendanceStatuses(slotStartTime))" clearable
            :label="slotStartTime"
            v-model="selectedSlotStatus"
            @update:model-value="(status: AttendanceStatus) => {
              selectedSlotStartTime = slotStartTime
              selectedSlotStatus = status
              dialogGlobalModifVisible = true
            }">
              </v-select>
            
            <v-dialog v-model="dialogGlobalModifVisible">
              <v-row justify="center" v-if="selectedSlotStartTime">
                <v-card style="max-width: 500px">
                  <v-card-title>
                    <v-icon color="primary" class="mr-1">mdi-alert</v-icon> Modifier l'appel de l'horaire {{ selectedSlotStartTime.replace(":", "h") }} pour tout l'étage
                  </v-card-title>
                  <v-card-item>
                    <p class="py-2" v-if="selectedSlotStatus != AttendanceStatus.NONE">
                      En validant la modification, le statut <b>{{ AttendanceUtils.getTitleForStatus(selectedSlotStatus) }}</b> sera appliquée à l'ensemble des élèves de l'étage pour l'horaire <b>{{ selectedSlotStartTime.replace(":", "h") }}</b>.
                    </p>
                    <p class="py-2" v-if="selectedSlotStatus === AttendanceStatus.NONE">
                      En validant, l'appel sera <b>remis à zéro</b> pour l'ensemble des élèves de l'étage pour l'horaire <b>{{ selectedSlotStartTime.replace(":", "h") }}</b> .
                    </p>
                  </v-card-item>
                  <v-card-actions>
                    <v-row no-gutters>
                      <v-spacer></v-spacer>
                      <v-btn text="Annuler" @click="resetDialogAttendanceGlobal()"></v-btn>
                      <v-btn text="Appliquer" variant="plain" @click="async () => {
                        await updateAllStudents(selectedSlotStartTime, selectedSlotStatus)
                        resetDialogAttendanceGlobal()
                      }"></v-btn>
                    </v-row>
                  </v-card-actions>
                </v-card>
              </v-row>
          </v-dialog>
        </v-col>
      </v-row>

      <v-switch
        v-model="rulesEnabled"
        label="Rêgles pour les soirées actives"
        color="blue-darken-4"
        density="compact"
        hide-details
        inline
        inset
      >
      </v-switch>
    </v-card-item>

    <v-progress-linear indeterminate color="primary" v-if="updating"></v-progress-linear>
  </v-card>

  <v-skeleton-loader
    type="card"
    v-for="card in loading ? [1, 2, 3] : []"
    v-bind:key="card"
  ></v-skeleton-loader>

  <AttendanceItem
    v-for="room in floor?.rooms.filter(x => !loading && x.students.length > 0)" :key="room.id" 
    :room="room"
    :currentDay="currentDay"
    :disabled="updating"
    :rules-enabled="rulesEnabled"
    @updateSlot="(student: Student, slotStartTime: string, status: AttendanceStatus) => updateStudent(room, student, slotStartTime, status)">
  </AttendanceItem>

  <v-btn icon="mdi-arrow-up" size="small" variant="flat" color="primary" style="position: fixed; right: 20px; bottom: 20px;" @click="scrollTop()"></v-btn>
</template>
