export const levelItems = [{label: '2nde', level: 0}, {label: '1ère', level: 1}, {label: 'Terminale', level: 2}]

export class Classname {
  id: string = ''
  classname: string = ''
  level: number = -1

  constructor(classname: string, level: number) {
    this.classname = classname
    this.level = level
  }
}
