<script setup lang="ts">
import { useLoanStore } from '@/stores/loanstore';
import { useProfileStore } from '@/stores/profile';
import { Floor } from '@/stores/types/floor';
import { Loan } from '@/stores/types/loan';
import type { Student } from '@/stores/types/student';
import type { PropType } from 'vue';
import { onUpdated } from 'vue';
import { ref, type Ref } from 'vue';

const props = defineProps({
  floor: {type: Object as PropType<Floor>, required: true},
})

const loanStore = useLoanStore()
const profileStore = useProfileStore()

const loans: Ref<Array<Loan>> = ref([])
const loanToEdit: Ref<Loan | undefined> = ref()
const loanToDelete: Ref<Loan | undefined> = ref()
const loanToBorrow: Ref<Loan | undefined> = ref()
const loanToGiveBack: Ref<Loan | undefined> = ref()

const students: Ref<Array<Student>> = ref([])
const selectedStudent: Ref<Student | undefined> = ref()

onUpdated(async() => {
  loans.value = await loanStore.getByFloorId(props.floor.id)
  students.value = props.floor.rooms.flatMap(x => x.students)
})

//
// Handle dialog
//
function resetDialogContent() {
  loanToEdit.value = undefined
  loanToDelete.value = undefined
  loanToBorrow.value = undefined
  loanToGiveBack.value = undefined  
}
function showDialog(kind: "edit" | "delete" | "borrow" | "giveback", loan: Loan, student: Student | undefined = undefined) {
  resetDialogContent()
  selectedStudent.value = student
  switch(kind) {
    case "edit":
      loanToEdit.value = loan
      break;
    case "delete":
      loanToDelete.value = loan
      break;
    case "borrow":
      loanToBorrow.value = loan
      break;
    case "giveback":
      loanToGiveBack.value = loan
      break;
  }
}

//
// Loan management
//
async function addNewLoan() {
  let loan = new Loan()
  loan.objectName = "Nouvel objet"
  loan.quantity = 1
  loan = await loanStore.create(props.floor.id, loan)
  loanToEdit.value = loan
  loans.value = loans.value.concat(loanToEdit.value)
}

async function removeLoan() {
  await loanStore.remove(props.floor.id, loanToDelete.value!!.id)
  loans.value = loans.value.filter(x => x.id != loanToDelete.value!!.id)
  loanToDelete.value = undefined
  loanToEdit.value = undefined
}

function stockEmpty(loan: Loan): boolean {
  return loan.nbBorrowed >= loan.quantity
}

//
// Student/Loan associations
//
async function associateLoanToStudent(loan: Loan, student: Student, borrow: boolean) {
  await loanStore.associateLoanToStudent(student, loan, borrow)
  // refresh loans to ensure being up-to-date and to have a clean UI
  loans.value = await loanStore.getByFloorId(props.floor.id)
}

</script>

<template>
  <v-container v-if="profileStore.current?.role == 'admin'">
    <v-card>
      <v-card-title>
        <v-row no-gutters justify="center">
          <v-btn
            @click="addNewLoan()"
            prepend-icon="mdi-plus">Nouvel objet de prêt</v-btn>
        </v-row>
      </v-card-title>
    </v-card>
  </v-container>

  <v-dialog>
    <template v-slot:activator="{ props: loanDialog }">
      <v-container>
        <v-card>
          <v-card-title>
            Liste des objets de prêt
          </v-card-title>
          <v-card-item>
            <v-list>
              <v-list-item @click="showDialog('edit', loan)" v-for="loan in loans" :key="loan.id" rounded="shaped" color="primary">
                <v-list-item-title class="text-primary">
                  <b>{{ loan.objectName }}</b>
                </v-list-item-title>
                <v-list-item-title :class="stockEmpty(loan) ? 'text-error' : 'text-primary'">
                  Prêtés: <b>{{ loan.nbBorrowed }}</b> / Quantité: <b>{{ loan.quantity }}</b>
                </v-list-item-title>
                <v-list-item-title>
                  <v-row no-gutters justify="end">
                    <v-row no-gutters justify="center" align="center" v-if="loan.students.length > 0">
                      <div>Prêté à: </div>
                      <v-btn
                        v-for="student in loan.students" :key="student.id"
                        class="ma-1"
                        size="small"
                        color="error"
                        v-bind="loanDialog"
                        @click="showDialog('giveback', loan, student)"
                        prepend-icon="mdi-account-minus"
                      >
                        {{ student.lastname }} {{ student.firstname }}
                      </v-btn>
                    </v-row>
                    <v-spacer v-if="loan.students.length <= 0"></v-spacer>
                    <v-btn class="ma-1"
                      prepend-icon="mdi-account-plus" size="small"
                      :disabled="stockEmpty(loan)"
                      @click="showDialog('borrow', loan)"
                      v-bind="loanDialog"
                    >
                      Prêter
                    </v-btn>
                  </v-row>
                </v-list-item-title>
              </v-list-item>
              <v-label v-if="loans.length <= 0">Aucun objet de prêt à cet étage...</v-label>
            </v-list>
          </v-card-item>
        </v-card>
      </v-container>

      <v-container v-if="profileStore.current?.role == 'admin'">
        <v-card v-if="loanToEdit" class="mb-4">
          <v-card-title>
            <v-row no-gutters align="center">
              <v-icon class="mr-2" color="primary" icon="mdi-shield"></v-icon>
              Modifier un objet de prêt
              <v-spacer></v-spacer>
              <v-btn class="ma-1" @click="loanToEdit = undefined" icon="mdi-close" variant="outlined">
              </v-btn>
            </v-row>
          </v-card-title>
          <v-card-item>
            <v-row no-gutters align="center" justify="center">
              <v-text-field class="ma-1" style="min-width: 100px;" v-model="loanToEdit.objectName" label="Nom de l'objet" variant="underlined"
                @update:model-value="loanStore.update(floor.id, loanToEdit!!)"></v-text-field>
              
              <v-text-field
                style="max-width: 140px;"
                type="number"
                class="ma-1"
                v-model="loanToEdit.quantity"
                label="Quantité disponible"
                @update:model-value="(quantity) => {
                  loanToEdit!!.quantity = parseInt(quantity)
                  loanStore.update(floor.id, loanToEdit!!)
                }"></v-text-field>
              
              <v-card-item>
                <v-row no-gutters>
                  <v-spacer></v-spacer>
                  <v-btn class="ma-1" v-bind="loanDialog" @click="loanToDelete = loanToEdit" prepend-icon="mdi-delete-forever">
                    Supprimer définitivement
                  </v-btn>
                </v-row>
              </v-card-item>
            </v-row>
          </v-card-item>
        </v-card>
      </v-container>
    </template>

    <!-- Definitively delete modale -->
    <template v-slot:default="{ isActive }">
      <v-row justify="center" v-if="loanToDelete">
        <v-card style="width: 600px; max-width: 90%; max-height: calc(100vh - 40px); overflow-y: auto;">
          <v-card-title>
            <v-row no-gutters>
              Supprimer définitivement
            </v-row>
          </v-card-title>
          <v-card-item>
            Etes-vous certain de vouloir supprimer définitivement l'objet de prêt <b>{{ loanToDelete.objectName }} ?</b><br/>
            Cette action est irréversible.
          </v-card-item>
          <v-card-actions>
            <v-row no-gutters>
              <v-spacer></v-spacer>
              <v-btn text="Fermer" @click="() => {
                loanToDelete = undefined
                isActive.value = false
              }"></v-btn>
              <v-btn text="Supprimer" @click="() => {
                removeLoan()
                isActive.value = false
              }"></v-btn>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-row>

      <v-row justify="center" v-if="loanToBorrow">
        <v-card style="width: 600px; max-width: 90%; max-height: calc(100vh - 40px); overflow-y: auto;">
          <v-card-title>
            <v-row no-gutters>
              Prêter un {{ loanToBorrow.objectName }}
            </v-row>
          </v-card-title>
          <v-card-item>
            <p>
              Sélectionner l'élève à qui vous allez prêter un <b>{{ loanToBorrow.objectName }}</b> :
              <br/>
            </p>

            <v-autocomplete
              label="Elève"
              :items="students.filter(x => loanToBorrow?.students.find(borrowedStudent => borrowedStudent.id == x.id) == undefined)"
              :item-title="item => item?.lastname + ' ' + item?.firstname"
              :item-value="item => item"
              v-model="selectedStudent"
              >
            </v-autocomplete>
          </v-card-item>
          <v-card-actions>
            <v-row no-gutters>
              <v-spacer></v-spacer>
              <v-btn text="Fermer" @click="() => {
                resetDialogContent()
                isActive.value = false
              }"></v-btn>
              <v-btn text="Prêter" :disabled="!selectedStudent" @click="async () => {
                await associateLoanToStudent(loanToBorrow!!, selectedStudent!!, true)
                resetDialogContent()
                isActive.value = false
              }"></v-btn>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-row>

      <v-row justify="center" v-if="loanToGiveBack">
        <v-card style="width: 600px; max-width: 90%; max-height: calc(100vh - 40px); overflow-y: auto;">
          <v-card-title>
            <v-row no-gutters>
              Rendre un {{ loanToGiveBack.objectName }}
            </v-row>
          </v-card-title>
          <v-card-item>
            <p>
              L'élève <b>{{ selectedStudent?.lastname }} {{ selectedStudent?.firstname }}</b> a rendu un <b>{{ loanToGiveBack.objectName }}</b> ?
            </p>
          </v-card-item>
          <v-card-actions>
            <v-row no-gutters>
              <v-spacer></v-spacer>
              <v-btn text="Fermer" @click="() => {
                resetDialogContent()
                isActive.value = false
              }"></v-btn>
              <v-btn text="Objet rendu" :disabled="!selectedStudent" @click="async () => {
                await associateLoanToStudent(loanToGiveBack!!, selectedStudent!!, false)
                resetDialogContent()
                isActive.value = false
              }"></v-btn>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-row>
    </template>
  </v-dialog>
</template>
