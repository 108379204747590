import axios from 'axios'
import { defineStore } from 'pinia'
import { ref, type Ref } from 'vue'
import { baseUrl } from './baseurl'
import { Classname } from './types/classname'

const url = `${baseUrl}/classnames`

export const useClassnamesStore = defineStore('classnames', () => {
  const classnames: Ref<Array<Classname>> = ref([])

  async function all() {
    try {
      const data = await axios.get(`${url}`)
      classnames.value = data.data as Array<Classname>
    } catch (error) {
      console.log(error)
    }
  }

  async function addNew(): Promise<Classname | undefined> {
    try {
      const newClassname = new Classname("", 0)
      const classnameCreatedResp = await axios.post(`${url}`, newClassname)
      return classnameCreatedResp.data
    } catch (error) {
      console.log(error)
    }
  }

  async function update(classname: Classname) {
    try {
      await axios.put(`${url}/${classname.classname}`, classname)

      // ensure classes are still properly sorted
      classnames.value = classnames.value.sort((a, b) => {
        if (a.level === b.level) {
          return a.classname.localeCompare(b.classname)
        }
        return a.level - b.level
      })
    } catch (error) {
      console.log(error)
    }
  }

  async function remove(classname: Classname) {
    try {
      await axios.delete(`${url}/${classname.classname}`)
      classnames.value = classnames.value.filter(x => x.id != classname.id)
    } catch (error) {
      console.log(error)
    }
  }

  return { classnames, all, addNew, update, remove }
})
