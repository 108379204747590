<script setup lang="ts">
import router from '@/router'
import { passwordRules } from '@/stores/password';
import { useProfileStore } from '@/stores/profile';
import type { Ref } from 'vue'
import { onMounted } from 'vue';
import { ref } from 'vue'
import { useChallengeV3  } from 'vue-recaptcha'

const { execute } = useChallengeV3('submit')

const display: Ref<string> = ref("login")
const profileStore = useProfileStore()
const error: Ref<string | undefined> = ref()

const emailRules = [(v: string) => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail invalide']
const passwordMatchRule = [
  (v: string) => v === profileStore.password || 'Les mots de passe ne correspondent pas !'
]

const displaySignupMessage: Ref<boolean> = ref(false)

onMounted(async () => {
  await profileStore.getProfile()
  displaySignupMessage.value = profileStore.current != null && !profileStore.current.accountValidated
})

async function loginOrSignup() {
  error.value = ""

  // Handle recaptcha and bot connections
  let response
  try {
    response = await execute()
  } catch (e) {
    console.error(e)
    return
  }
  if (!response) {
    error.value = "Vous avez été identifié comme un robot. Si ce n'est pas le cas, contactez l'administrateur du site."
    return
  }

  // trim email
  profileStore.email = profileStore.email.trim()

  if (display.value === 'login') {
    error.value = await profileStore.login()
    // redirect
    if (!error.value) {
      if (profileStore.current?.accountValidated)
        router.push({ path: '/' })
      else
        router.push({ path: '/profile' })
    }
  }
  else {
    error.value = await profileStore.signup()
    if (!error.value) {
      // display message about admin having to validate the account
      displaySignupMessage.value = true
    }
  }
}

</script>

<template>
  <main>
    <div class="d-flex align-center justify-center" v-if="!displaySignupMessage" style="min-height: calc(100vh - 54px);">
      <v-col>
        <v-container class="text-center mx-auto" style="width: 340px">
          <v-row no-gutters justify="space-between" class="my-md-8">
            <img src="@/assets/logo_transparent.png" style="max-width:50px">
            <h1>Gestion internat</h1>
          </v-row>
        </v-container>
        <v-sheet width="340" class="mx-auto">
          <v-card>
            <v-card-title v-if="display === 'login'">Connection</v-card-title>
            <v-card-title v-if="display === 'signup'">Création nouveau compte</v-card-title>
            <v-card-item>
              <v-form fast-fail @submit.prevent="loginOrSignup()">
                <v-text-field v-model="profileStore.email" label="Adresse email" :rules="emailRules" @update:model-value="(val: string)  => profileStore.email = val.trim()"></v-text-field>

                <v-text-field v-model="profileStore.password" type="password" label="Mot de passe"
                  :rules="passwordRules"></v-text-field>
                <v-text-field v-if="display === 'signup'" v-model="profileStore.passwordConfirmation" type="password"
                  label="Confirmation du mot de passe" :rules="passwordMatchRule"></v-text-field>

                <!-- <a href="#" class="text-body-2 font-weight-regular">Mot de passe oublié ?</a> -->
                <v-btn v-if="display === 'login'" type="submit" color="primary" block class="mt-2">Se connecter</v-btn>
                <v-btn v-if="display === 'signup'" type="submit" color="primary" block class="mt-2">S'enregistrer</v-btn>

              </v-form>

              <v-row no-gutters v-if="display === 'login'" block class="mt-2">
                <v-spacer></v-spacer>
                <a class="blue-text" href="#" @click.prevent="display = 'signup'">S'enregistrer</a>
              </v-row>
              <v-row no-gutters v-if="display === 'signup'" block class="mt-2">
                <v-spacer></v-spacer>
                <a class="blue-text" href="#" @click.prevent="display = 'login'">Se connecter</a>
              </v-row>

              <v-label class="text-center text-red mt-2" block>
                {{ error }}
              </v-label>
            </v-card-item>
          </v-card>
        </v-sheet>
      </v-col>
    </div>

    <v-container v-if="displaySignupMessage">
      <v-card>
        <v-card-title>Enregistrement réussi</v-card-title>
        <v-card-text>
          Un administrateur doit maintenant vous valider l'accès.
        </v-card-text>
      </v-card>
    </v-container>
  </main>
</template>
