<script setup lang="ts">
import { useCookiesStore } from '@/stores/cookiesstore'
import { ref } from 'vue'
import logo from '@/assets/cookie.png'

const cookieStore = useCookiesStore()
const dialog = ref(false)

</script>

<style>
.justified {
  text-align: justify;
}
</style>

<template>
  <v-banner
    :avatar="logo"
    stacked
    bg-color="secondary"
    color="white"
    elevation="4"
  >
    <template v-slot:text>
      Lycée Internat utilise des cookies dans un but de fonctionnement du site.
      Vous pouvez consulter notre page sur la confidentialité des données <a href="/privacy" target="_blank">Politique de confidentialité</a>.
      En cliquant "Accepter" ou en continuant sur le site, vous accepter l'usage de ces cookies.
    </template>

    <template v-slot:actions>
      <v-dialog v-model="dialog" max-width="800">
        <template v-slot:activator="{ props }">
          <v-btn
            class="text-none"
            color="blue-darken-4"
            rounded="0"
            variant="outlined"
            v-bind="props"
          >
            Gestion des Cookies
          </v-btn>
        </template>

        <v-card title="Cookie Settings">
          <v-card-text>
            <p class="pb-4 justified">
              Lycée Internat utilise des cookies dans le but fournir et d'améliorer l'expérience utilisateur.
              Vous pouvez en apprendre plus sur les cookies que nous utilisons dans la page de confidentialité des données.
            </p>

            <v-list-subheader class="font-weight-black text-high-emphasis">Cookies nécessaires</v-list-subheader>

            <p class="mb-4 justified">Ces cookies sont nécessaires au bon fonctionnement du site et ne peuvent pas être coupés.</p>

            <v-list-subheader class="font-weight-black text-high-emphasis">Cookies de performance</v-list-subheader>

            <v-switch
              v-model="cookieStore.performance"
              :label="cookieStore.performance ? 'On' : 'Off'"
              color="blue-darken-4"
              density="compact"
              hide-details
              inline
              inset
            ></v-switch>

            <p class="mb-4 justified">
              Envoie les erreurs que vous pouvez avoir afin de les corriger au plus vite et d'améliorer l'expérience utilisateur.
              Des informations anonymes sur la navigation sont également envoyées afin d'améliorer le parcours utilisateur.
              Aucune donnée personnelle n'est transmise.
              L'objectif est de réduire les erreurs et de vous fournir la meilleure expérience possible.
            </p>

          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions class="justify-center px-6 py-3">
            <v-btn
              class="flex-grow-1 text-none"
              color="blue-darken-4"
              rounded="0"
              variant="plain"
              @click="() => {
                cookieStore.refuseAll()
                dialog = false
              }"
            >
              Tout refuser
            </v-btn>

            <v-btn
              class="text-white flex-grow-1 text-none"
              color="blue-darken-4"
              rounded="0"
              variant="flat"
              @click="() => {
                cookieStore.save()
                dialog = false
              }"
            >
              Enregistrer et Accepter
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-btn
        class="text-none ms-4 text-white"
        color="blue-darken-4"
        rounded="0"
        variant="flat"
        @click="() => {
          cookieStore.acceptAll()
          dialog = false
        }"
      >
        Accepter
      </v-btn>
    </template>
  </v-banner>
</template>
