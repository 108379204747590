import axios from "axios";
import dayjs from "dayjs";
import qs from "qs"

const ISODateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/;

const isIsoDateString = (value: unknown): value is string => {
  return typeof value === "string" && ISODateFormat.test(value);
};

export const handleDates = (data: unknown) => {
  if (isIsoDateString(data)) return dayjs(data)
  if (data === null || data === undefined || typeof data !== "object") return data

  for (const [key, val] of Object.entries(data)) {
    // @ts-expect-error this is a hack to make the type checker happy
    if (isIsoDateString(val)) data[key] = dayjs(val)
    else if (typeof val === "object") handleDates(val)
  }
  return data
}

axios.interceptors.response.use((rep) => {
  handleDates(rep.data);
  return rep;
})

// FIXME: not working...
axios.interceptors.request.use((config) => {
  config.paramsSerializer = (params) => qs.stringify(params, {
    serializeDate: (date: Date) => dayjs(date).format() });
  return config;
})
