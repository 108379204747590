<script setup lang="ts">
import { useFloorsStore } from '@/stores/floors'
import type { Floor } from '@/stores/types/floor';
import { onMounted, onUpdated, ref, type Ref } from 'vue';

const floorStore = useFloorsStore()
const floor: Ref<Floor|undefined> = ref()
const props = defineProps({
  floorId: {type: String, required: true},
})

onMounted(async () => {
  floor.value = await floorStore.load(props.floorId)
})
onUpdated(async () => {
  floor.value = await floorStore.load(props.floorId)
})
</script>

<template>
  <v-card class="my-4">
    <v-card-title>
      <h3 class="mb-4">Effectifs de l'étage</h3>
    </v-card-title>
    <v-card-item style="max-width: 400px">
      <v-row no-gutters justify="space-between">
        <div class="mr-8">Nombre de chambres</div>
        <div style="font-weight: bold">
          {{
            floor?.rooms.reduce(
              (acc, room) => (room.nbStudents > 0 ? acc + 1 : acc),
              0
            )
          }}
        </div>
      </v-row>
      <v-row no-gutters justify="space-between">
        <div class="mr-8">Nombre de places</div>
        <div style="font-weight: bold">
          {{ floor?.rooms.reduce((acc, room) => acc + room.nbStudents, 0) }}
        </div>
      </v-row>
      <v-row no-gutters justify="space-between">
        <div class="mr-8">Nombre de places <b>attribuées</b></div>
        <div style="font-weight: bold">
          {{ floor?.rooms.reduce((acc, room) => acc + room.students.length, 0) }}
        </div>
      </v-row>
      <v-row no-gutters justify="space-between" class="text-red">
        <div class="mr-8">Nombre de places <b>disponibles</b></div>
        <div style="font-weight: bold">
          {{
            floor?.rooms.reduce(
              (acc, room) => acc + (room.nbStudents - room.students.length),
              0
            )
          }}
        </div>
      </v-row>
    </v-card-item>
  </v-card>
</template>
