<script setup lang="ts">
import { onMounted, ref, type Ref } from 'vue';
import { useStudentsStore } from '@/stores/students';
import type { Student } from '@/stores/types/student';
import { levelItems } from '@/stores/types/classname'
import { useProfileStore } from '@/stores/profile';
import StudentItem from '@/components/StudentItem.vue';

const profileStore = useProfileStore()

const studentsStore = useStudentsStore()
const studentEdit: Ref<Student | undefined> = ref()

const levelItemsChoice: Array<{label: string, level: number}> = Object.assign([], levelItems)
levelItemsChoice.unshift({label: '', level: -1})

// cumulative filters
const level: Ref<number> = ref(-1)
const partialStudentName: Ref<string> = ref("")
const noRoom: Ref<boolean> = ref(false)
const firstLetters: Ref<boolean> = ref(false)

onMounted(async () => {
  await studentsStore.findByCriteria(level.value, partialStudentName.value, noRoom.value, firstLetters.value)
})

function scrollToStudentEdit() {
  setTimeout(() => document.getElementById("studentEdit")?.scrollIntoView(), 250)
}

function scrollTop() {
  window.scrollTo({top: 0, behavior: 'smooth'})
}
</script>
<style>
html {
  scroll-behavior: smooth
}
</style>

<template>
  <main>
    <v-dialog max-width="500">
      <template v-slot:activator="{ props: activatorProps }">
        <v-container>
          <v-col>
            <h1 class="text-center">Elèves</h1>
          </v-col>

          <v-card v-if="profileStore.current?.role === 'admin'">
            <v-card-actions>
              <v-row no-gutters>
                <v-spacer></v-spacer>
                <v-btn variant="flat" @click="studentsStore.addNew().then((newStudent) => {
                  studentEdit = newStudent
                  scrollToStudentEdit()
                })" prepend-icon="mdi-plus">Nouvel élève</v-btn>
                <v-spacer></v-spacer>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-container>

        <v-container v-if="studentEdit" id="studentEdit">
          <v-card>
            <v-card-title>
              <v-row no-gutters align="center">
                {{ studentEdit.firstname === "" ? "Nouvel élève" : (profileStore.current?.role === 'admin' ? "Modifier élève" : "Détails de l'élève") }}
                <v-spacer></v-spacer>
                <v-btn icon="mdi-close" variant="outlined" @click="studentEdit = undefined"></v-btn>
              </v-row>
            </v-card-title>
            <v-card-item>
              <v-container>
                <StudentItem
                  :student="studentEdit"
                  :readonly="profileStore.current?.role !== 'admin'" 
                  @update="async (student: Student) => {
                    await studentsStore.update(student)
                    await studentsStore.findByCriteria(level, partialStudentName, noRoom, firstLetters)
                  }"
                >
                </StudentItem>
              </v-container>
            </v-card-item>
            <v-card-item v-if="studentEdit.previousRooms && studentEdit.previousRooms.length">
              Constitution des chambres les années précédentes
              <v-list>
                <v-list-item v-for="room in studentEdit.previousRooms" :key="room.period" :subtitle="room.period + ' - chambre ' + room.shortName" :title="room.roomStudents.join(', ')"></v-list-item>
              </v-list>
            </v-card-item>
            <v-card-actions v-if="profileStore.current?.role === 'admin'">
              <v-row no-gutters>
                <v-spacer></v-spacer>
                <v-btn variant="flat" v-bind="activatorProps" prepend-icon="mdi-delete-forever">Supprimer définitivement</v-btn>
                <v-spacer></v-spacer>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-container>

        <v-container v-if="studentsStore.allStudents.length > 0">
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-title>
                <v-card-title style="padding: 0;">Statistiques</v-card-title>
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <v-card-subtitle>
                  Effectifs Internat
                </v-card-subtitle>
                <v-card-item>
                  <v-col style="max-width: 400px">
                    <v-row no-gutters justify="space-between">
                      <div>Nombre total d'élèves</div>
                      <div style="font-weight: bold" class="text-primary">
                        {{ studentsStore.allStudents.length }}
                      </div>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row no-gutters justify="space-between">
                      <div>Nombre total d'élèves <b>Filles</b></div>
                      <div style="font-weight: bold" class="text-primary">
                        {{ studentsStore.allStudents.filter(x => x.gender == "female").length }}
                      </div>
                    </v-row>
                    <v-row no-gutters justify="space-between">
                      <div>Nombre total d'élèves <b>Garçons</b></div>
                      <div style="font-weight: bold" class="text-primary">
                        {{ studentsStore.allStudents.filter(x => x.gender == "male").length }}
                      </div>
                    </v-row>
                  </v-col>
                </v-card-item>

                <v-card-subtitle>
                  Par classe
                </v-card-subtitle>
                <v-card-item>
                  <v-col style="max-width: 400px;" v-for="currentLevel in levelItemsChoice.filter(x => x.level > -1)" :key="currentLevel.level">
                    <v-row no-gutters justify="space-between">
                      <div class="mr-8">Classe de <b class="text-primary">{{ currentLevel.label }}</b></div>
                      <div style="font-weight: bold" class="text-primary">
                        {{ studentsStore.allStudents.filter(x => x.level == currentLevel.level).length }}
                      </div>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row no-gutters justify="space-between">
                      <div class="mr-8">Nombre d'élèves <b>Filles</b></div>
                      <div style="font-weight: bold" class="text-primary">
                        {{ studentsStore.allStudents.filter(x => x.gender == "female" && x.level == currentLevel.level).length }}
                      </div>
                    </v-row>
                    <v-row no-gutters justify="space-between">
                      <div class="mr-8">Nombre d'élèves <b>Garçons</b></div>
                      <div style="font-weight: bold" class="text-primary">
                        {{ studentsStore.allStudents.filter(x => x.gender == "male" && x.level == currentLevel.level).length }}
                      </div>
                    </v-row>
                  </v-col>
                </v-card-item>

              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-container>
        
        <v-container>
          <v-card>
            <v-card-title>Recherche</v-card-title>
            <v-card-item>
              <v-col no-gutters>
                <v-combobox label="Niveau" :items="levelItemsChoice" item-title="label" item-value="level" :clearable="false"
                  :model-value="levelItemsChoice.find(x => x.level === level)" @update:modelValue="(selected) => {
                    level = selected.level
                    studentsStore.findByCriteria(level, partialStudentName, noRoom, firstLetters)
                  }">
                </v-combobox>
                <v-text-field label="Chercher par nom ou prénom" v-model="partialStudentName" clearable
                  @update:model-value="studentsStore.findByCriteria(level, partialStudentName, noRoom, firstLetters)"
                  @click:clear="() => {
                    partialStudentName = ''
                    studentsStore.findByCriteria(level, partialStudentName, noRoom, firstLetters)
                  }">
                </v-text-field>
                <v-row>
                  <v-spacer></v-spacer>
                  <v-switch v-model="firstLetters" label="Nom de famille commence par..." color="blue"
                    @update:model-value="studentsStore.findByCriteria(level, partialStudentName, noRoom, firstLetters)"></v-switch>
                </v-row>
                <v-switch v-model="noRoom" label="N'afficher QUE les élèves qui ne sont pas affectés à une chambre" color="blue"
                  @update:model-value="studentsStore.findByCriteria(level, partialStudentName, noRoom, firstLetters)"></v-switch>
              </v-col>
            </v-card-item>
            <v-card-item>
              <v-list>
                <v-list-item v-for="student in studentsStore.students" :key="student.id" 
                  @click="async () => {
                    // get complete details about student (including previous rooms in which he/she was)
                    studentEdit = undefined
                    studentEdit = await studentsStore.findById(student.id)
                    scrollToStudentEdit()
                  }">
                  <v-list-item-title>{{ student.lastname }} {{ student.firstname }}</v-list-item-title>
                  <v-list-item-subtitle class="text-green">
                    Classe {{ student.classname }}
                    <v-icon v-if="student.isRoomReferent" color="red">mdi-fire</v-icon>
                    <v-icon v-if="student.isPairMaster" color="success">mdi-certificate</v-icon>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle v-if="student.isLikeLevel0" class="text-red" style="font-size: 11px; font-weight: bold;">Régime seconde</v-list-item-subtitle>
                  <v-list-item-subtitle v-if="student.isPai || student.isPap || student.isPps" class="text-orange" style="font-size: 11px; font-weight: bold;">{{ student.isPai ? "PAI" : "" }} {{ student.isPap ? "PAP" : "" }} {{ student.isPps ? "PPS" : "" }}</v-list-item-subtitle>
                </v-list-item>
              </v-list>
            </v-card-item>
          </v-card>
        </v-container>
      </template>

      <template v-slot:default="{ isActive }">
        <v-card title="Supprimer définitivement">
          <v-card-text>
            Etes-vous certain de vouloir supprimer définitivement l'étudiant <b>{{ studentEdit?.lastname }} {{ studentEdit?.firstname }}</b> ?<br/>
            Cette action est irréversible.
          </v-card-text>
          <v-card-actions>
            <v-row no-gutters>
              <v-spacer></v-spacer>
              <v-btn text="Annuler" @click="isActive.value = false"></v-btn>
              <v-btn text="Supprimer" @click="studentsStore.remove(studentEdit!!).then(() => {
                isActive.value = false
                studentEdit = undefined
              })"></v-btn>
            </v-row>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>

    <v-btn v-if="studentsStore.students?.length > 12" icon="mdi-arrow-up" size="small" variant="flat" color="primary" style="position: fixed; right: 20px; bottom: 20px; z-index: 20;" @click="scrollTop()"></v-btn>
  </main>
</template>