import type { AttendanceStatus } from './attendancestatus'

export const SLOT1 = "18:10"
export const SLOT2 = "20:15"
export const SLOT3 = "21:30"
export const attendanceSlotsNotWednesday = [SLOT1, SLOT2, SLOT3]
export const attendanceSlotsWednesday = [SLOT2, SLOT3]

export class AttendanceSlot {
  startTime: string
  status: AttendanceStatus | undefined

  constructor(startTime: string) {
    this.startTime = startTime
  }
}
