import axios from 'axios'
import { defineStore } from 'pinia'
import { ref, type Ref } from 'vue'
import { baseUrl } from './baseurl'
import { Room } from './types/room'
import type dayjs from 'dayjs'
import { AttendanceUtils } from '@/utils/attendance'

const url = `${baseUrl}/rooms`

export const useRoomsStore = defineStore('rooms', () => {
  const rooms: Ref<Array<Room>> = ref([])
  const newRoom: Ref<Room | undefined> = ref()
  const selected: Ref<Room | undefined> = ref()

  async function findByFloorId(floorId: string) {
    try {
      const data = await axios.get(`${url}?floorId=${floorId}`)
      rooms.value = data.data
      rooms.value
        .sort((a, b) => (a.isArchived && !b.isArchived ? 1 : -1))
        .sort((a: Room, b: Room) => a.index - b.index)
    } catch (error) {
      console.log(error)
    }
  }

  async function load(roomId: string): Promise<Room> {
    try {
      const data = await axios.get(`${url}/${roomId}`)
      selected.value = data.data as Room
      return data.data as Room
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status == 404) {
        console.error(`Room with id ${roomId} not found`, error)
      } else {
        console.log(error)
      }
      throw error
    }
  }

  async function loadWithWeekAttendance(roomId: string, attendanceDate: dayjs.Dayjs): Promise<Room> {
    try {
      const data = await axios.get(`${url}/${roomId}/attendance-week?attendanceDate=${attendanceDate.format("YYYY-MM-DD")}`)
      const room = data.data as Room
      room.students.forEach(student => {
        AttendanceUtils.updateStats(student)
      })
      selected.value = room
      return data.data as Room
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status == 404) {
        console.error(`Room with id ${roomId} not found`, error)
      } else {
        console.log(error)
      }
      throw error
    }
  }

  async function addNew(floorId: string): Promise<Room | undefined> {
    try {
      newRoom.value = new Room(floorId)
      const roomCreatedResp = await axios.post(`${url}`, newRoom.value)
      rooms.value = rooms.value.concat(roomCreatedResp.data).sort((a, b) => {
        if (a.id === roomCreatedResp.data.id) return -1
        if (b.id === roomCreatedResp.data.id) return 1
        return a.shortName.localeCompare(b.shortName)
      })
      return roomCreatedResp.data
    } catch (error) {
      console.log(error)
    }
  }

  function resetNew(floorId: string) {
    newRoom.value = new Room(floorId)
  }

  async function update(room: Room) {
    try {
      const data = await axios.put(`${url}/${room.id}`, room)
      room = data.data as Room
      rooms.value = rooms.value.map((x) => (x.id == room.id ? room : x))
    } catch (error) {
      console.log(error)
    }
  }

  async function archive(room: Room) {
    try {
      room.isArchived = true
      await update(room)
    } catch (error) {
      console.log(error)
    }
  }

  async function unarchive(room: Room) {
    try {
      room.isArchived = false
      await update(room)
    } catch (error) {
      console.log(error)
    }
  }

  async function remove(room: Room) {
    try {
      await axios.delete(`${url}/${room.id}`)
      rooms.value = rooms.value.filter(x => x.id != room.id)
    } catch (error) {
      console.log(error)
    }
  }

  return { rooms, newRoom, selected, findByFloorId, load, loadWithWeekAttendance, addNew, resetNew, update, archive, unarchive, remove }
})
